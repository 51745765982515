import { React, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import toast, { Toaster } from 'react-hot-toast';

function PagarModal({show, handleClose, handleRefetch, rowData}){
    const [loading, setLoading] = useState(false);
    const enviarPagoLiquidacion = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/PagarLiquidacion.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nLiquidacion: rowData.nLiquidacion})
            });
    
            const data = await response.json();
    
            if (data.success) {
                toast.success('LIQUIDACION NO.'+rowData.nLiquidacion+' PAGADA CORRECTAMENTE', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                handleClose();
            } else {
                setLoading(false);
                toast.error(data.error, {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    return(
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static"keyboard={false}>
                <Form onSubmit={enviarPagoLiquidacion}>
                    <Modal.Header closeButton>
                        <Modal.Title>PAGAR LIQUIDACION NO.{rowData.nLiquidacion}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <span>SEGURO QUE QUIERES PAGAR LA LIQUIDACION NO.{rowData.nLiquidacion}</span>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> $ ACTUALIZANDO</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">$ PAGAR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default PagarModal;