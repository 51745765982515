import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGear, faPenToSquare, faTrash} from '@fortawesome/free-solid-svg-icons';
import Sliderbar from '../../utils/Sliderbar';
import RegistrarOperador from '../../utils/RegistrarOperador';
import EditarOperadores from '../../utils/EditarOperador';
import EliminarModal from '../../utils/EliminarModal';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQuery } from "react-query";

function Operadores(){
    const [show, setShow] = useState(false);
    const [showModalEditarOperadores, setShowModalEditarOperadores] = useState(false);
    const [showModalEliminarOperadores, setShowModalEliminarOperadores] = useState(false);
    const [rowData, setRowDataOperadores] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    const { data: operadoresData, isLoading, refetch } = useQuery("operadores", mostrarOperadores);

    useEffect(() => {
        document.title = "OPERADORES";
        refetch();
    }, [refetch]);

    async function mostrarOperadores() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/MostrarOperadores.php');
            const data = await response.json();
    
            if (data.success) {
                return data.operadores;
            } else {
                return [];
                // console.log("ERROR AL SELECCIONAR OPERADORES");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            return [];
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const handleShowModalEditarOperador = (rowData) => {
        setRowDataOperadores(rowData)
        setShowModalEditarOperadores(true);
    }

    const handleCloseModalEditarOperador = () =>{
        setShowModalEditarOperadores(false);
    }

    const handleShowModalEliminarOperador = (rowData) => {
        setRowDataOperadores(rowData)
        setShowModalEliminarOperadores(true);
    }

    const handleCloseModalEliminarOperador = () =>{
        setShowModalEliminarOperadores(false);
    }

    const handleRefetch = () =>{
        refetch();
    }

    const accionBoton = (rowData) => {
        return( 
            <Dropdown drop="left">
                <Dropdown.Toggle className = "btnAccion" variant="success" id="dropdown-basic">
                    <i><FontAwesomeIcon icon={faGear}/></i>
                </Dropdown.Toggle>
        
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleShowModalEditarOperador(rowData)}><i><FontAwesomeIcon icon={faPenToSquare} /></i> EDITAR</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowModalEliminarOperador(rowData)}><i><FontAwesomeIcon icon={faTrash} /></i> BORRAR</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const header = (
        <Col md={3}>
            <Form.Control type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="BUSCAR..."></Form.Control>
        </Col>
    );
    

    return (
        <div>
            <Sliderbar/>
            <section className="home-section">
                <div className="home-content">
                    <i className='bx bx-menu'></i>
                </div>
                <h4 className='centered'>OPERADORES</h4>
                <Button className='buttonNew' variant="primary" onClick={handleShow}><i className='bx bxs-user'></i> NUEVO</Button>
                <RegistrarOperador show={show} handleClose={handleClose} handleRefetch={handleRefetch} />

                {
                    isLoading ?
                    <div className='centered'>
                        <div className="spinner"></div>
                        <span><br />CARGANDO OPERADORES...</span>
                    </div>
                    :
                    <DataTable value={operadoresData} dataKey="licencia" stripedRows showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" globalFilter={globalFilter} header={header} emptyMessage="SIN OPERADORES REGISTRADOS.">
                        <Column field="nombre" header="NOMBRE" style={{ minWidth: '18px' }} sortable></Column>
                        <Column field="licencia" header="N. LICENCIA" style={{ minWidth: '25px' }} sortable></Column>
                        <Column header="ACCION" body={accionBoton} style={{ minWidth: '15px' }}/>
                    </DataTable>
                }
                
            </section>
            <EditarOperadores show = {showModalEditarOperadores} handleClose={handleCloseModalEditarOperador} handleRefetch={handleRefetch} rowData={rowData}/>
            <EliminarModal show={showModalEliminarOperadores} handleClose={handleCloseModalEliminarOperador} handleRefetch={handleRefetch} rowData={rowData} tipo={"operador"}/>
        </div>
    );
}

export default Operadores;
