import React, { useState } from 'react';
import './css/login-css.css';
export function Login({ setUser }) {
    const [usuarioLogin, setUsuarioLogin] = useState("");
    const [passWordLogin, setPassWordLogin] = useState("");
    const [errorLogin, setErrorLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    const evitarEnvioLogin = async (event) => {
        event.preventDefault();

        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/Login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: usuarioLogin, password: passWordLogin })
            });
            const data = await response.json();

            if (data.success) {
                setUser([data.login]);
                setLoading(false);
                setErrorLogin(false);
            } else {
                setErrorLogin(true);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            setErrorLogin(true);
            setLoading(false);
        }
    };

    return (
        <div className="parent">
            <div className="login-container">
                <h2 id = "tituloForm">INICIAR SESIÓN</h2>
                <form id="login-form" onSubmit={evitarEnvioLogin}>
                    <input
                        type="text"
                        name='usernameLogin'
                        id='usernameLogin'
                        placeholder='NOMBRE DE USUARIO'
                        value={usuarioLogin}
                        onChange={e => setUsuarioLogin(e.target.value)}
                    /><br />
                    <input
                        type="password"
                        name='passwordLogin'
                        id='passwordLogin'
                        placeholder='CONTRASEÑA'
                        value={passWordLogin}
                        onChange={e => setPassWordLogin(e.target.value)}
                    /><br />
                    {
                        loading ?
                        <button type='submit' id='iniciarForm' disabled><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> INICIAR SESIÓN</button>
                        :
                        <button type='submit' id='iniciarForm'> INICIAR SESIÓN</button>
                    }
                </form>
                {errorLogin && <div className="error error-danger">CONTRASEÑA Y/O USUARIO INVÁLIDOS</div>}
            </div>
        </div>
    );
}

export default Login;
