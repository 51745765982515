//CASETAS
import { updateGastosGeneralesEnLaVuelta } from "./cuentasGenerales";

export function updateTotalCasetasEditar() {
    var casetas = 0;
    var defaultAmount = document.getElementById("amountCasetasEditar").value;
    casetas += parseFloat(defaultAmount) || 0;
    var amounts = document.querySelectorAll("#extraFieldsCasetas input[type='number']");
    amounts.forEach(function(amountInput) {
        casetas += parseFloat(amountInput.value) || 0;
    });
    document.getElementById("totalAmountCasetasEditar").value = casetas;
    updateGastosGeneralesEnLaVuelta();
}

var camposAdicionaleCasetas = [];
export function getInformacionCasetas(){
    var descripcionCasetasEditar = document.getElementById("descripcionCasetasEditar").value;
    var amountCasetasEditar = document.getElementById("amountCasetasEditar").value;
    var totalAmountCasetasEditar = document.getElementById("totalAmountCasetasEditar").value;

    var descripcionCasetasEditarExtras = document.querySelectorAll("#extraFieldsCasetas input[type='text']");
    var amountCasetasEditarExtras = document.querySelectorAll("#extraFieldsCasetas input[type='number']");
    camposAdicionaleCasetas.length = 0;

    for (let i = 0; i < descripcionCasetasEditarExtras.length; i++) {
        var descripcionCasetasEditarExtra = descripcionCasetasEditarExtras[i].value;
        var amountCasetasEditarExtra = amountCasetasEditarExtras[i].value;

        camposAdicionaleCasetas.push({DescripcionCasetasExtras: descripcionCasetasEditarExtra, CantidadCasetasExtras: amountCasetasEditarExtra });
    }

    if (descripcionCasetasEditar === "") {
        return {
            success: false,
            error: "INGRESE LA DESCRIPCION N.1 EN CASETAS"
        };
    }

    if (amountCasetasEditar === "") {
        return {
            success: false,
            error: "INGRESE LA CANTIDAD N.1 EN CASETAS"
        };
    }
    
    for (let i = 0; i < descripcionCasetasEditarExtras.length; i++) {
        if (descripcionCasetasEditarExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA DESCRIPCION N."+(i+2)+" EN CASETAS"
            };
        }

        if (amountCasetasEditarExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA CANTIDAD N."+(i+2)+" EN CASETAS"
            };
        }
        
    }

    var informacionCasetas = {
        descripcionCasetas: descripcionCasetasEditar,
        amountCasetas: amountCasetasEditar,
        camposAdicionaleCasetas: camposAdicionaleCasetas,
        totalAmountCasetas: totalAmountCasetasEditar
    };

    return informacionCasetas;
}