import { useState, useEffect } from "react";
import Login from "./Login";
import LiquidacionAdmin from "./roles/admin/Liquidacion";
import LiquidacionUser from "./roles/user/Liquidacion";
import LiquidacionGeneralAdmin from "./roles/admin/LiquidacionGeneral";
import LiquidacionGeneralUser from "./roles/user/LiquidacionGeneral";
import OperadoresAdmin from './roles/admin/Operadores';
import OperadoresAdminUser from './roles/user/Operadores';
import TractoAdmin from './roles/admin/Tracto';
import TractoUser from './roles/user/Tracto';
import ProtectedRoute from './ProtectedRoute';
import { Routes, Route } from "react-router-dom";

function App() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("login");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleSetUser = (user) => {
    setUser(user);
    localStorage.setItem("login", JSON.stringify(user));
  };

  let content;
  if (user.length > 0 && user[0].permisos === "admin") {
    content = <LiquidacionAdmin />;
  } else if (user.length > 0 && user[0].permisos !== "admin") {
    content = <LiquidacionUser />;
  } else {
    content = <Login setUser={handleSetUser} />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={content} />
        <Route path="/liquidacion" element={<ProtectedRoute user={user}>{user.length > 0 && user[0].permisos === "admin" ? <LiquidacionAdmin /> : <LiquidacionUser />}</ProtectedRoute>}/>
        <Route path="/liquidaciongeneral" element={<ProtectedRoute user={user}>{user.length > 0 && user[0].permisos === "admin" ? <LiquidacionGeneralAdmin /> : <LiquidacionGeneralUser />}</ProtectedRoute>}/>
        <Route path="/operadores" element={<ProtectedRoute user={user}>{user.length > 0 && user[0].permisos === "admin" ? <OperadoresAdmin /> : <OperadoresAdminUser />}</ProtectedRoute>}/>
        <Route path="/tractos" element={<ProtectedRoute user={user}>{user.length > 0 && user[0].permisos === "admin" ? <TractoAdmin /> : <TractoUser />}</ProtectedRoute>}/>
      </Routes>
    </div>
  );
}

export default App;
