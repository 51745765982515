import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';

function RegistrarTractoCaja({ show, handleClose, handleRefetch }) {
    const initialState = {
        economico: '',
        placa: '',
        modelo: '',
        tipo: 'TRACTO'
    };

    const [values, setValues] = useState(initialState);
    const [loading, setLoading] = useState(false);
    
    const evitarEnvioRegistrarTracto = async (event) => {
        event.preventDefault();
        if (values.economico === ""){
            toast.error('FALTA N. ECONOMICO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (values.placa === ""){
            toast.error('FALTA LA PLACA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (values.modelo === ""){
            toast.error('FALTA EL MODELO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (values.tipo === ""){
            toast.error('FALTA EL TIPO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_insert/InsertarTractoCaja.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ economico: values.economico, placa: values.placa, modelo: values.modelo, tipo: values.tipo})
            });

            const data = await response.json();

            if (data.success) {
                toast.success('TRACTO/CAJA '+values.economico.toUpperCase()+' CON PLACAS '+values.placa.toUpperCase()+' AGREGADO CORRECTAMENTE.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                setValues(initialState);
                handleClose();
            } else {
                setLoading(false);
                toast.error('LA PLACA '+values.placa.toUpperCase()+' YA SE ENCUENTRA REGISTRADA.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    return(
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static"keyboard={false}>
                <Form onSubmit={evitarEnvioRegistrarTracto}>
                    <Modal.Header closeButton>
                        <Modal.Title>REGISTRAR TRACTO/CAJA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={3}>
                                    <Form.Label htmlFor="economico">N. ECONOMICO</Form.Label>
                                    <Form.Control type='text' id = "economico" name='economico' placeholder='N. ECONOMICO' value={values.economico} onChange={e => setValues({...values, economico: e.target.value})}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label htmlFor="placa">PLACAS</Form.Label>
                                    <Form.Control type='text' id = "placa" name='placa' placeholder='PLACAS' value={values.placa} onChange={e => setValues({...values, placa: e.target.value})}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label htmlFor="modelo">MODELO</Form.Label>
                                    <Form.Control type='text' id = "modelo" name='modelo' placeholder='MODELO' value={values.modelo} onChange={e => setValues({...values, modelo: e.target.value})}></Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Label htmlFor="modelo">TIPO</Form.Label>
                                    <Form.Select id = "tipo" name = "tipo" value={values.tipo} onChange={e => setValues({...values, tipo: e.target.value})} aria-label="Default select example">
                                        <option disabled >TRACTO O CAJA</option>
                                        <option value="TRACTO">TRACTO</option>
                                        <option value="CAJA">CAJA</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> REGISTRANDO TRACTO/CAJA</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">REGISTRAR TRACTO/CAJA</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default RegistrarTractoCaja