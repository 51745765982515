import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import toast, { Toaster } from 'react-hot-toast';

//JS
import { updateTotalFleteGeneral, getInformacionFleteGeneral } from '../js/liquidacionGeneral/fleteGeneral';
import { updateTotalCasetasEditar, getInformacionCasetas } from '../js/liquidacionGeneral/casetas';
import { updateTotalGastosRegularesEditar, getInformacionGastosRegulares } from '../js/liquidacionGeneral/gastosRegulares';
import { updateTotalOtrosGastosEditar, getInformacionOtrosGastos } from '../js/liquidacionGeneral/otrosGastos';
import { updateTotalDieselEditar, updateKmLtDieselEditar, getInformacionDiesel } from '../js/liquidacionGeneral/diesel';
import { getInformacionSueldoComisionOperador } from '../js/liquidacionGeneral/sueldoComision';
import { getInformacionCuentasGenerales } from '../js/liquidacionGeneral/cuentasGenerales';

function EditarLiquidacionGeneral({show, handleClose, handleRefetch, rowData}){

    const addFieldsFleteGeneral = useCallback((count = 1) => {
        const fieldsFleteGeneral = rowData.conceptosFlete.camposAdicionalesSueldoComision.slice(0, count).map((campo) => ({
            fecha: campo.Fecha,
            inicio: campo.Inicio,
            destino: campo.Destino,
            carga: campo.Carga,
            flete: campo.Cantidad,
            iva: campo.ivaFleteGeneralExtra,
            retencion: campo.retencionFleteGeneralExtra,
            total: campo.totalFleteFleteGeneralExtra,
            comision: campo.Comision,
            barco: campo.Barco,
            transfer: campo.Transfer

        }));
        setExtraFieldsFleteGeneral(prevFields => [...prevFields, ...fieldsFleteGeneral]);
    }, [rowData]);

    const addFieldsCasetas = useCallback((count = 1) => {
        const fieldsCasetas = rowData.casetas.camposAdicionaleCasetas.slice(0, count).map((campo) => ({
            descripcion: campo.DescripcionCasetasExtras,
            cantidad: campo.CantidadCasetasExtras
        }));
        setExtraFieldsCasetas(prevFields => [...prevFields, ...fieldsCasetas]);
    }, [rowData]);

    const addFieldGastosRegulares = useCallback((count = 1) => {
        const fieldsGastosRegulares = rowData.gastosRegulares.camposAdicionaleGastosRegulares.slice(0, count).map((campo) => ({
            descripcion: campo.DescripcionGastosRegularesExtras,
            cantidad: campo.CantidadGastosRegularesExtras
        }));
        setExtraFieldsGastosRegulares(prevFields => [...prevFields, ...fieldsGastosRegulares]);
    }, [rowData]);

    const addFieldOtrosGastos = useCallback((count = 1) => {
        const fieldsOtrosGastos = rowData.otrosGastos.camposAdicionaleOtrosGastos.slice(0, count).map((campo) => ({
            descripcion: campo.DescripcionOtrosGastosExtras,
            cantidad: campo.CantidadOtrosGastosExtras
        }));
        setExtraFieldsOtrosGastos(prevFields => [...prevFields, ...fieldsOtrosGastos]);
    }, [rowData]);

    const addFieldSueldoComision = useCallback((count = 1) => {
        const fieldsSueldoComision = rowData.conceptosFlete.camposAdicionalesSueldoComision.slice(0, count).map((campo) => ({
            inicio: campo.Inicio,
            destino: campo.Destino
        }));
        setExtraFieldsSueldoComision(prevFields => [...prevFields, ...fieldsSueldoComision]);
    }, [rowData]);

    useEffect(() => {
        if (show){
            setExtraFieldsFleteGeneral([]);
            setExtraFieldsSueldoComision([]);
            addFieldsFleteGeneral(rowData.conceptosFlete.camposAdicionalesSueldoComision?.length);
            addFieldSueldoComision(rowData.conceptosFlete.camposAdicionalesSueldoComision?.length);

            setExtraFieldsCasetas([]);
            if (rowData.casetas !== ""){
                addFieldsCasetas(rowData.casetas.camposAdicionaleCasetas?.length);
            }

            setExtraFieldsGastosRegulares([]);
            if (rowData.gastosRegulares !== ""){
                addFieldGastosRegulares(rowData.gastosRegulares.camposAdicionaleGastosRegulares?.length);
            }

            setExtraFieldsOtrosGastos([]);
            if (rowData.otrosGastos !== ""){
                addFieldOtrosGastos(rowData.otrosGastos.camposAdicionaleOtrosGastos?.length);
            }

        }
    }, [show, addFieldsFleteGeneral, addFieldsCasetas, addFieldGastosRegulares, addFieldOtrosGastos, addFieldSueldoComision, rowData.casetas, rowData.gastosRegulares, rowData.otrosGastos, rowData.conceptosFlete?.camposAdicionalesSueldoComision?.length, rowData.casetas?.camposAdicionaleCasetas?.length, rowData.gastosRegulares?.camposAdicionaleGastosRegulares?.length, rowData.otrosGastos?.camposAdicionaleOtrosGastos?.length])
    
    const [loading, setLoading] = useState(false);
    const [extraFieldsFleteGeneral, setExtraFieldsFleteGeneral] = useState([]);

    //CASETAS/////////////////////////////////////////////////////////////////
    const [extraFieldsCasetas, setExtraFieldsCasetas] = useState([]);

    const addExtraFieldCasetas = () => {
        setExtraFieldsCasetas([...extraFieldsCasetas, {
            descripcion: "",
            cantidad: ""
        }]);
    };

    //ACTUALIZAR EL STATE DE CASETAS
    const handleFieldChangeCasetasEditar = (index, field, value) => {
        const newFields = [...extraFieldsCasetas];
        newFields[index][field] = value;
        setExtraFieldsCasetas(newFields);
    };

    //BORRAR EL STATE DE CASETAS
    const removeExtraFieldsCasetas = (index) => {
        const newFields = extraFieldsCasetas.filter((_, i) => i !== index);
        setExtraFieldsCasetas(newFields);
    }

    //GASTOS REGULARES/////////////////////////////////////////
    const [extraFieldsGastosRegulares, setExtraFieldsGastosRegulares] = useState([]);

    const addExtraFieldGastosRegulares = () => {
        setExtraFieldsGastosRegulares([...extraFieldsGastosRegulares, {
            descripcion: "",
            cantidad: ""
        }]);
    };

    //ACTUALIZAR EL STATE DE GASTOS REGULARES
    const handleFieldChangeGastosRegulares = (index, field, value) => {
        const newFields = [...extraFieldsGastosRegulares];
        newFields[index][field] = value;
        setExtraFieldsGastosRegulares(newFields);
    };

    //BORRAR EL STATE DE GASTOS REGULARES
    const removeExtraFieldsGastosRegulares = (index) => {
        const newFields = extraFieldsGastosRegulares.filter((_, i) => i !== index);
        setExtraFieldsGastosRegulares(newFields);
    }

    //OTROS GASTOS/////////////////////////////////////////
    const [extraFieldsOtrosGastos, setExtraFieldsOtrosGastos] = useState([]);

    const addExtraFieldOtrosGastos = () => {
        setExtraFieldsOtrosGastos([...extraFieldsOtrosGastos, {
            descripcion: '',
            cantidad: ''
        }]);
    };

    //ACTUALIZAR EL STATE DE OTROS GASTOS
    const handleFieldChangeOtrosGastos = (index, field, value) => {
        const newFields = [...extraFieldsOtrosGastos];
        newFields[index][field] = value;
        setExtraFieldsOtrosGastos(newFields);
    };

    //BORRAR EL STATE DE OTROS GASTOS
    const removeExtraFieldsOtrosGastos = (index) => {
        const newFields = extraFieldsOtrosGastos.filter((_, i) => i !== index);
        setExtraFieldsOtrosGastos(newFields);
    }

    //SUELDO COMISION DEL OPERADOR/////////////////////////////////////////
    const [extraFieldsSueldoComision, setExtraFieldsSueldoComision] = useState([]);

    const [collapseState, setCollapseState] = useState({
        fleteGeneral: true,
        casetas: false,
        gastosRegulares: false,
        otrosGastos: false,
        diesel: false,
        sueldoComision: false,
        cuentasGenerales: false
    });

    const toggleCollapse = (section) => {
        setCollapseState(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    }

    useEffect(() => {
        if (show){
            updateTotalFleteGeneral();
        }
    }, [show, extraFieldsFleteGeneral]);

    useEffect(() => {
        if (show){
            updateTotalCasetasEditar();
        }
    }, [show, extraFieldsCasetas]);

    useEffect(() => {
        if (show){
            updateTotalGastosRegularesEditar();
        }
    }, [show, extraFieldsGastosRegulares]);

    useEffect(() => {
        if (show){
            updateTotalOtrosGastosEditar();
        }
    }, [show, extraFieldsOtrosGastos]);

    useEffect(() => {
        if (show){
            updateTotalDieselEditar();
            updateKmLtDieselEditar();
        }
    }, [show, extraFieldsSueldoComision]);

    const enviarEdicionLiquidacionGeneral = async (event) => {
        event.preventDefault();
        var nLiquidacion = document.getElementById("idEditarLiquidacionGeneral").value;

        // FLETE GENERAL
        var informacionFleteGeneral = getInformacionFleteGeneral();
        if (informacionFleteGeneral.success === false) {
            toast.error(informacionFleteGeneral.error, {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        var informacionFleteGeneralJSON = JSON.stringify(informacionFleteGeneral);

        //CASETAS
        var informacionCasetas = getInformacionCasetas();
        if (informacionCasetas.success === false) {
            toast.error(informacionCasetas.error, {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        var informacionCasetasJSON = JSON.stringify(informacionCasetas);
        
        //GASTOS REGULARES
        var informacionGastosRegulares = getInformacionGastosRegulares();
        if (informacionGastosRegulares.success === false) {
            toast.error(informacionGastosRegulares.error, {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        var informacionGastosRegularesJSON = JSON.stringify(informacionGastosRegulares);

        // OTROS GASTOS
        var informacionOtrosGastos = getInformacionOtrosGastos();
        if (informacionOtrosGastos.success === false) {
            toast.error(informacionOtrosGastos.error, {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        var informacionOtrosGastosJSON = JSON.stringify(informacionOtrosGastos);

        //DIESEL
        var informacionDiesel = getInformacionDiesel();
        if (informacionDiesel.success === false) {
            toast.error(informacionDiesel.error, {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        var informacionDieselJSON = JSON.stringify(informacionDiesel);

        //SUELDO - COMISION DEL OPERADOR
        var informacionSueldoComisionOperador = getInformacionSueldoComisionOperador();
        var informacionSueldoComisionOperadorJSON = JSON.stringify(informacionSueldoComisionOperador);

        //CUENTAS GENERALES
        var informacionCuentasGenerales = getInformacionCuentasGenerales();
        var informacionCuentasGeneralesJSON = JSON.stringify(informacionCuentasGenerales);

        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/ActualizarLiquidacionGeneral.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nLiquidacion: nLiquidacion, informacionFleteGeneral: informacionFleteGeneralJSON, informacionCasetas: informacionCasetasJSON,
                    informacionGastosRegulares: informacionGastosRegularesJSON, informacionOtrosGastos: informacionOtrosGastosJSON, informacionDiesel: informacionDieselJSON,
                    informacionSueldoComisionOperador: informacionSueldoComisionOperadorJSON, informacionCuentasGenerales: informacionCuentasGeneralesJSON
                })
            });

            const data = await response.json();
    
            if (data.success) {
                toast.success("LIQUIDACION GENERAL #"+nLiquidacion+ " ACTUALIZADA CORRECTAMENTE", {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                handleCloseFunction();
            } else {
                setLoading(false);
                toast.error(data.error, {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                // console.log("ERROR AL REGISTRAR LA NUEVA LIQUIDACION");
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }



    };

    const handleCloseFunction = () => {
        setCollapseState(prevState => ({
            ...prevState,
            fleteGeneral: true,
            casetas: false,
            gastosRegulares: false,
            otrosGastos: false,
            diesel: false,
            sueldoComision: false,
            cuentasGenerales: false
        }));
        handleClose();
    }

    const [dragging, setDragging] = useState(false);
    
    const handleDragStart = (e, index) => {
        setDragging(true);
        e.dataTransfer.setData('text/plain', index);
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    
    const handleDropCasetas = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsCasetas];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsCasetas(updatedFields);
    };

    const handleDropGastosRegulares = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsGastosRegulares];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsGastosRegulares(updatedFields);
    };

    const handleDropOtrosGastos = (e, dropIndex) => {
        e.preventDefault();
        setDragging(false);
        const dragIndex = e.dataTransfer.getData('text/plain');
        
        // Mueve el campo arrastrado a la nueva posición
        const updatedFields = [...extraFieldsOtrosGastos];
        const [movedField] = updatedFields.splice(dragIndex, 1);
        updatedFields.splice(dropIndex, 0, movedField);
        setExtraFieldsOtrosGastos(updatedFields);
    };

    return(
        <div className='modal-content'>
            <Modal show={show} fullscreen = {true} onHide={handleCloseFunction} backdrop="static" keyboard={false}>
                <Form onSubmit={enviarEdicionLiquidacionGeneral}>
                    <Modal.Header closeButton>
                        <Modal.Title>ACTUALIZAR LIQUIDACION GENERAL #{rowData.nLiquidacion}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 150px)'}}>
                        <Row>
                            <Col md={2}>
                                <Form.Label htmlFor="idEditarLiquidacionGeneral" className="form-label">N. LIQUIDACION:</Form.Label>
                                <Form.Control type='number' className='form-control' id='idEditarLiquidacionGeneral' defaultValue={rowData.nLiquidacion} readOnly></Form.Control>
                            </Col>
                            <Col md={4}>
                                <Form.Label htmlFor="nombreEditarOperadorLiquidacionGeneral" className="form-label">NOMBRE DEL OPERADOR:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='nombreEditarOperadorLiquidacionGeneral' defaultValue={rowData.nombreOp} disabled>
                                    <option value= {rowData.nombreOp}>{rowData.nombreOp}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="tractoEditarEconomicoLiquidacionGeneral" className="form-label">ECONOMICO TRACTO:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='tractoEditarEconomicoLiquidacionGeneral' defaultValue={rowData.tracto} disabled>
                                    <option value={rowData.tracto}>{rowData.tracto}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="cajaEditarEconomicoLiquidacionGeneral" className="form-label">ECONOMICO CAJA:</Form.Label>
                                <Form.Select  type='text' className='form-control' id='cajaEditarEconomicoLiquidacionGeneral' defaultValue={rowData.caja} disabled>
                                    <option disabled value={rowData.caja}>{rowData.caja}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <hr style={{border: "1px solid #ffff"}} />

                        <div className="row menuColaps">
                            <div className="col-md-12">
                                <button className="btn btn-primary" style={{border: collapseState.fleteGeneral ? 'var(--main-color) solid 1px !important' : 'none' }}  type="button" data-bs-toggle="collapse" data-bs-target="#fleteGeneral" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('fleteGeneral')}>
                                    FLETE GENERAL
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.casetas ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#casetas" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('casetas')}>
                                    CASETAS
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.gastosRegulares ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#gastosRegulares" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('gastosRegulares')}>
                                    GASTOS REGULARES
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.otrosGastos ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#otrosGastos" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('otrosGastos')}>
                                    OTROS GASTOS
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.diesel ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#diesel" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('diesel')}>
                                    DIESEL
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.sueldoComision ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#sueldoComisionOperador" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('sueldoComision')}>
                                    SUELDO - COMISION OPERADOR
                                </button>
                                <button className="btn btn-primary" style={{border: collapseState.cuentasGenerales ? 'var(--main-color) solid 1px !important' : 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#cuentasGenerales" aria-expanded="false" aria-controls="collapseExample" onClick={() => toggleCollapse('cuentasGenerales')}>
                                    CUENTAS GENERALES
                                </button>
                            </div>
                        </div>

                        {/* FLETE GENERAL */}
                        <div className="collapse show" id="fleteGeneral">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>FLETE GENERAL</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="fechaFleteGeneralEditar" className="form-label">FECHA:</Form.Label>
                                                <Form.Control type='date' className='form-control' id='fechaFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.fechaSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="inicioFleteGeneralEditar" className="form-label">INICIO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='inicioFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.inicioSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="destinoFleteGeneralEditar" className="form-label">DESTINO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='destinoFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.destinoSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="cargaFleteGeneralEditar" className="form-label">CARGA:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='cargaFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.cargaSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountFleteGeneralEditar" className="form-label">$ FLETE:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.amountSueldoComision} placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="ivaFleteGeneralEditar" className="form-label">% IVA:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='ivaFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.ivaFleteGeneral} placeholder='%' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="retencionFleteGeneralEditar" className="form-label">$ RETENCION:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='retencionFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.retencionFleteGeneral} placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="totalFleteGeneralEditar" className="form-label">$ TOTAL:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='totalFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.totalFleteGeneral} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="comisionFleteGeneralEditar" className="form-label">$ COMISION:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='comisionFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.comisionSueldoComision} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="barcoFleteGeneralEditar" className="form-label">$ BARCO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='barcoFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.barcoSueldoComision} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="transferFleteGeneralEditar" className="form-label">$ TRANSFER:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='transferFleteGeneralEditar' defaultValue={rowData.conceptosFlete?.transferSueldoComision} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsFleteGeneral">
                                            {extraFieldsFleteGeneral.map((field, index) => (
                                                <div id = {"campoFleteGeneral"+index} key={index}>
                                                    <Row className='text-center'>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`fechaFleteGeneralEditar${index}`} className="form-label">FECHA:</Form.Label>
                                                            <Form.Control type='date' className='form-control' id={`fechaFleteGeneralEditar${index}`} defaultValue={field.fecha} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`inicioFleteGeneralEditar${index}`} className="form-label">INICIO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`inicioFleteGeneralEditar${index}`} defaultValue={field.inicio} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`destinoFleteGeneralEditar${index}`} className="form-label">DESTINO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`destinoFleteGeneralEditar${index}`} defaultValue={field.destino} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`cargaFleteGeneralEditar${index}`} className="form-label">CARGA:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`cargaFleteGeneralEditar${index}`} defaultValue={field.carga} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountFleteGeneralEditar${index}`} className="form-label">$ FLETE:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountFleteGeneralEditar${index}`} defaultValue={field.flete} placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`ivaFleteGeneralEditar${index}`} className="form-label">% IVA:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`ivaFleteGeneralEditar${index}`} defaultValue={field.iva} placeholder='%' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`retencionFleteGeneralEditar${index}`} className="form-label">$ RETENCION:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`retencionFleteGeneralEditar${index}`} defaultValue={field.retencion} placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`totalFleteGeneralEditar${index}`} className="form-label">$ TOTAL:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`totalFleteGeneralEditar${index}`} defaultValue={field.total} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`comisionFleteGeneralEditar${index}`} className="form-label">$ COMISION:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`comisionFleteGeneralEditar${index}`} defaultValue={field.comision} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`barcoFleteGeneralEditar${index}`} className="form-label">$ BARCO:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`barcoFleteGeneralEditar${index}`} defaultValue={field.barco} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`transferFleteGeneralEditar${index}`} className="form-label">$ TRANSFER:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`transferFleteGeneralEditar${index}`} defaultValue={field.transfer} readOnly placeholder='$' onClick={() => updateTotalFleteGeneral()} onInput={() => updateTotalFleteGeneral()}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* CASETAS */}
                        <div className="collapse" id="casetas">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS GENERALES EN LA VUELTA <br/> CASETAS</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionCasetasEditar" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionCasetasEditar' defaultValue={rowData.casetas?.descripcionCasetas}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountCasetasEditar" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountCasetasEditar' defaultValue={rowData.casetas?.amountCasetas} onClick={() => updateTotalCasetasEditar()} onInput={() => updateTotalCasetasEditar()} placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsCasetas">
                                            {extraFieldsCasetas.map((field, index) => (
                                                <div id = {"campoCasetas"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropCasetas(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {10}>
                                                            <Form.Label htmlFor={`descripcionCasetasEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`descripcionCasetasEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeCasetasEditar(index, "descripcion", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountCasetasEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountCasetasEditar${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeCasetasEditar(index, "cantidad", e.target.value)} onClick={() => updateTotalCasetasEditar()} onInput={() => updateTotalCasetasEditar()} placeholder='$'></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldsCasetas(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldCasetas()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountCasetasEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountCasetasEditar' defaultValue={rowData.casetas?.totalAmountCasetas} readOnly placeholder='$'></Form.Control>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* GASTOS REGULARES */}
                        <div className="collapse" id="gastosRegulares">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS GENERALES EN LA VUELTA <br/> GASTOS REGULARES</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionGastosRegularesEditar" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionGastosRegularesEditar' defaultValue={rowData.gastosRegulares?.descripcionGastosRegulares}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountGastosRegularesEditar" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountGastosRegularesEditar' defaultValue={rowData.gastosRegulares?.amountGastosRegulares} onClick={() => updateTotalGastosRegularesEditar()} onInput={() => updateTotalGastosRegularesEditar()} placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsGastosRegulares">
                                            {extraFieldsGastosRegulares.map((field, index) => (
                                                <div id = {"campoGastosRegulares"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropGastosRegulares(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {10}>
                                                            <Form.Label htmlFor={`descripcionGastosRegularesEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`descripcionGastosRegularesEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeGastosRegulares(index, "descripcion", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`amountGastosRegularesEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountGastosRegularesEditar${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeGastosRegulares(index, "cantidad", e.target.value)} onClick={() => updateTotalGastosRegularesEditar()} onInput={() => updateTotalGastosRegularesEditar()} placeholder='$'></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldsGastosRegulares(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldGastosRegulares()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountGastosRegularesEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountGastosRegularesEditar' defaultValue={rowData.gastosRegulares?.totalAmountGastosRegulares} readOnly placeholder='$'></Form.Control>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* OTROS GASTOS */}
                        <div className="collapse" id="otrosGastos">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS GENERALES EN LA VUELTA <br/> OTROS GASTOS</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionOtrosGastosEditar" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionOtrosGastosEditar' defaultValue={rowData.otrosGastos?.descripcionOtrosGastos}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountOtrosGastosEditar" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountOtrosGastosEditar' defaultValue={rowData.otrosGastos?.amountOtrosGastos} onClick={() => updateTotalOtrosGastosEditar()} onInput={() => updateTotalOtrosGastosEditar()} placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsOtrosGastos">
                                            {extraFieldsOtrosGastos.map((field, index) => (
                                                <div id = {"campoOtrosGastos"+index} key={index} draggable="true" onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDropOtrosGastos(e, index)} style={{ border: dragging ? '1px dashed #000' : 'none' }}>
                                                    <Row className='text-center'>
                                                        <Col md = {10}>
                                                            <Form.Label htmlFor={`descripcionOtrosGastosEditar${index}`} className="form-label">DESCRIPCION:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`amountOtrosGastosEditar${index}`} value={field.descripcion} onChange={(e) => handleFieldChangeOtrosGastos(index, "descripcion", e.target.value)}></Form.Control>
                                                        </Col>
                                                        <Col md = {2}>
                                                            <Form.Label htmlFor={`descripcionOtrosGastosEditar${index}`} className="form-label">$ CANTIDAD:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountOtrosGastosEditar${index}`} value={field.cantidad} onChange={(e) => handleFieldChangeOtrosGastos(index, "cantidad", e.target.value)} onClick={() => updateTotalOtrosGastosEditar()} onInput={() => updateTotalOtrosGastosEditar()} placeholder='$'></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => removeExtraFieldsOtrosGastos(index)}>ELIMINAR</button>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant='info' style = {{marginTop: '10px', color: '#fff'}} onClick={() => addExtraFieldOtrosGastos()}>CAMPO ADICIONAL</Button>

                                        <Col md = {2} className='text-center' style={{marginLeft: '84%'}}>
                                            <Form.Label htmlFor="totalAmountOtrosGastosEditar" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='number' className='form-control' id='totalAmountOtrosGastosEditar' defaultValue={rowData.otrosGastos?.totalAmountOtrosGastos} readOnly placeholder='$'></Form.Control>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* DIESEL */}
                        <div className="collapse" id="diesel">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS GENERALES EN LA VUELTA <br/> DIESEL</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="tractoDieselEditar" className="form-label">TRACTO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='tractoDieselEditar' defaultValue={rowData.diesel?.tractoDiesel} placeholder='0' onClick={() => updateTotalDieselEditar()} onInput={() => updateTotalDieselEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="thermoDieselEditar" className="form-label">THERMO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='thermoDieselEditar' defaultValue={rowData.diesel?.thermoDiesel} placeholder='0' onClick={() => updateTotalDieselEditar()} onInput={() => updateTotalDieselEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="precioDieselEditar" className="form-label">$ DIESEL:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='precioDieselEditar' defaultValue={rowData.diesel?.precioDiesel} placeholder='$' step="0.01" onClick={() => updateTotalDieselEditar()} onInput={() => updateTotalDieselEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2} className='ms-auto'>
                                                <Form.Label htmlFor="totalPrecioDieselEditar" className="form-label">$ TOTAL:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='totalPrecioDieselEditar' defaultValue={rowData.diesel?.totalPrecioDiesel} placeholder='0' readOnly></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row className='text-center'>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="tractoKmEditar" className="form-label">KMS TRACTO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='tractoKmEditar' defaultValue={rowData.diesel?.tractoKm} placeholder='0' onClick={() => updateKmLtDieselEditar()} onInput={() => updateKmLtDieselEditar()}></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="tractoKmDieselEditar" className="form-label">DIESEL TRACTO:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='tractoKmDieselEditar' defaultValue={rowData.diesel?.tractoDiesel} placeholder='0' readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2} className='ms-auto'>
                                                <Form.Label htmlFor="totalKmLtDieselEditar" className="form-label">KMS / LTS:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='totalKmLtDieselEditar' defaultValue={rowData.diesel?.totalKmLtDiesel} placeholder='0' readOnly></Form.Control>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* SUELDO COMISION DEL OPERADOR */}
                        <div className="collapse" id="sueldoComisionOperador">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>GASTOS GENERALES EN LA VUELTA <br/> SUELDO COMISION DEL OPERADOR</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {3}>
                                                <Form.Label htmlFor="inicioSueldoComision" className="form-label">INICIO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='inicioSueldoComision' defaultValue={rowData.conceptosFlete?.inicioSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {3}>
                                                <Form.Label htmlFor="destinoSueldoComision" className="form-label">DESTINO:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='destinoSueldoComision' defaultValue={rowData.conceptosFlete?.destinoSueldoComision} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2} className='ms-auto'>
                                                <Form.Label htmlFor="amountSueldoComisionLiquidacionGeneral" className="form-label">$ FLETE:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountSueldoComisionLiquidacionGeneral' defaultValue={""} placeholder='$' readOnly></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <div id="extraFieldsSueldoComisionLiquidacionGeneral">
                                            {extraFieldsSueldoComision.map((field, index) => (
                                                <div id = {"camposSueldoComision_"+index} key={index}>
                                                    <Row className='text-center'>
                                                        <Col md = {3}>
                                                            <Form.Label htmlFor={`inicioSueldoComision${index}`} className="form-label">INICIO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`inicioSueldoComision${index}`} defaultValue={field.inicio} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {3}>
                                                            <Form.Label htmlFor={`destinoSueldoComision${index}`} className="form-label">DESTINO:</Form.Label>
                                                            <Form.Control type='text' className='form-control' id={`destinoSueldoComision${index}`} defaultValue={field.destino} readOnly></Form.Control>
                                                        </Col>
                                                        <Col md = {2} className='ms-auto'>
                                                            <Form.Label htmlFor={`amountSueldoComisionLiquidacionGeneral${index}`} className="form-label">$ FLETE:</Form.Label>
                                                            <Form.Control type='number' className='form-control' id={`amountSueldoComisionLiquidacionGeneral${index}`} defaultValue={""} placeholder='$' readOnly></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    <hr style={{border: "1px solid #ffff"}} />
                                                </div>
                                            ))}
                                        </div>

                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="subTotalAmountSueldoComisionLiquidacionGeneral" className="form-label">$ SUBTOTAL:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='subTotalAmountSueldoComisionLiquidacionGeneral' placeholder='$' defaultValue={rowData.conceptoSueldoComision?.subTotalAmountSueldoComision} readOnly></Form.Control>
                                        </Col>

                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="porcentajeAmountSueldoComision" className="form-label">% FLETE:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='porcentajeAmountSueldoComision' placeholder='%' defaultValue={rowData.porcentajeSueldoComision} readOnly></Form.Control>
                                        </Col>

                                        <Col md = {2} className='text-center' style={{marginLeft: "84%"}}>
                                            <Form.Label htmlFor="totalAmountSueldoComision" className="form-label">$ TOTAL:</Form.Label>
                                            <Form.Control type='text' className='form-control' id='totalAmountSueldoComision' placeholder='$' defaultValue={rowData.conceptoSueldoComision?.totalAmountSueldoComision} readOnly></Form.Control>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        {/* CUENTAS REGULARES */}
                        <div className="collapse" id="cuentasGenerales">
                            <div id="conceptList">
                                <Card style={{marginTop: "10px"}}>
                                    <Card.Header>
                                        <h5 className='text-center'>CUENTAS GENERALES</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionCuentasGenerales1" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionCuentasGenerales1' defaultValue={"DINERO RECIBIDO DE LOS FLETES"} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountCuentasGenerales1" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountCuentasGenerales1' defaultValue={rowData.cuentasGenerales?.amountCuentasGenerales1} readOnly placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionCuentasGenerales2" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionCuentasGenerales2' defaultValue={"GASTOS GENERALES EN LA VUELTA"} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountCuentasGenerales2" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountCuentasGenerales2' defaultValue={rowData.cuentasGenerales?.amountCuentasGenerales2} readOnly placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <Row className='text-center'>
                                            <Col md = {10}>
                                                <Form.Label htmlFor="descripcionCuentasGenerales3" className="form-label">DESCRIPCION:</Form.Label>
                                                <Form.Control type='text' className='form-control' id='descripcionCuentasGenerales3' defaultValue={"GANANCIAS DEL EQUIPO"} readOnly></Form.Control>
                                            </Col>
                                            <Col md = {2}>
                                                <Form.Label htmlFor="amountCuentasGenerales3" className="form-label">$ CANTIDAD:</Form.Label>
                                                <Form.Control type='number' className='form-control' id='amountCuentasGenerales3' defaultValue={rowData.cuentasGenerales?.amountCuentasGenerales3} readOnly placeholder='$'></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr style={{border: "1px solid #ffff"}} />
                                        <Col md = {8} style={{marginTop: '10px'}}>
                                        <Form.Label htmlFor="observacionesCuentasOperadorLiquidacionGeneral" className="form-label">OBSERVACIONES:</Form.Label>
                                        <textarea className="form-control" id="observacionesCuentasOperadorLiquidacionGeneral" style={{color: '#fff'}} defaultValue={rowData?.observaciones}></textarea>
                                    </Col>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFunction}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> ACTUALIZANDO LIQUIDACION</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">EDITAR LIQUIDACION</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default EditarLiquidacionGeneral;