import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import AnadirColaboradores from "./AnadirColaboradores";
import EditarColaboradores from "./EditarColaboradores";

function Colaboradores({ show, handleClose, handleOpen }) {
    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState();
    const [showModalAnadirColaboradores, setModalAnadirColaboradores] = useState(false);
    const [showModalEditarColaboradores, setShowModalEditarColaboradores] = useState(false);
    const [loading, setLoading] = useState(false);

    const userLogeado = localStorage.getItem("login");
    var userLogeadoUsuario = JSON.parse(userLogeado);
    var usuarioLogin = userLogeadoUsuario[0].usuario;

    const handleOpenModalAnadirColaboradores = () => {
        handleClose();
        setModalAnadirColaboradores(true);
    };

    const handleCloseModalAnadirColaboradores = () => {
        handleOpen();
        setModalAnadirColaboradores(false);
    };

    const handleOpenModalEditarColaboradores = (colaborador) => {
        handleClose();
        setShowModalEditarColaboradores(true);
        setColaboradorSeleccionado(colaborador);
    }

    const handleCloseModalEditarColaboradores = () => {
        handleOpen();
        setShowModalEditarColaboradores(false);
    }

    const obtenerColaboradores = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/ObtenerColaboradores.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: usuarioLogin })
            });
            const data = await response.json();

            if (data.success) {
                setLoading(false);
                setColaboradores(data.colaboradores);
            } else {
                setLoading(false);
                //console.log(data.error);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }
    }, [usuarioLogin]);

    useEffect(() => {
        if (show) {
            obtenerColaboradores();
        }
    }, [show, obtenerColaboradores]);

    return (
        <div className='modal-content'>
            <Modal show={show} size="xl" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>COLABORADORES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 d-flex">
                            {
                                loading ?
                                <Card className="cardColaboradores">
                                    <Card.Body>
                                        <Card.Title className="centered"><i><FontAwesomeIcon icon={faUser} /></i> USUARIO</Card.Title>
                                        <Card.Text><span className="placeholder col-12"></span> <br /><span className="placeholder col-12"></span></Card.Text>

                                    </Card.Body>
                                    <Card.Footer className="centered"><span className="placeholder col-6"></span></Card.Footer>
                                </Card>
                                :
                                colaboradores.length === 0 ? (
                                    <span className="centered">NO SE ENCONTRARON COLABORADORES.</span>
                                ) : (
                                    colaboradores.map((colaborador, index) => (
                                        <div key={index}>
                                            <Card className="cardColaboradores">
                                                <Card.Body>
                                                    <Card.Title className="centered"><i><FontAwesomeIcon icon={faUser} /></i> USUARIO</Card.Title>
                                                    <Card.Text><span>NOMBRE: {colaborador.usuario.toUpperCase()}<br />CONTRASEÑA: {colaborador.contrasena}</span></Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="centered"><Button className="btnFormularios" variant="primary" onClick={() => handleOpenModalEditarColaboradores(colaborador.usuario)}><FontAwesomeIcon icon={faPenToSquare} /> EDITAR</Button></Card.Footer>
                                            </Card>
                                        </div>
                                    ))
                                )
                            }
                            
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                    <Button className='btnFormularios' variant="primary" type="submit" onClick={handleOpenModalAnadirColaboradores}><i><FontAwesomeIcon icon={faPlus} /></i> COLABORADOR</Button>
                </Modal.Footer>
            </Modal>
            <AnadirColaboradores show={showModalAnadirColaboradores} handleClose={handleCloseModalAnadirColaboradores}/>
            <EditarColaboradores show = {showModalEditarColaboradores} handleClose={handleCloseModalEditarColaboradores} colaboradorSeleccionado = {colaboradorSeleccionado}/>
        </div>
    );
}

export default Colaboradores;
