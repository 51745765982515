import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';

function EditarPerfil({ show, handleClose }) {
    const userLogeado = localStorage.getItem("login");
    var userLogeadoUsuario = JSON.parse(userLogeado);
    var usuarioLogin = userLogeadoUsuario[0].usuario;
    const [loading, setLoading] = useState(false);
    
    const obtenerPerfil = useCallback(async () => {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/ObtenerDatosPerfil.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: usuarioLogin })
            });

            const data = await response.json();

            if (data.success) {
                setValues((prevValues) => ({ ...prevValues, contrasenaActual: data.datosLogin.contrasena }));
            } else {
                // console.log("ERROR AL OBTENER DATOS DE USUARIO");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }, [usuarioLogin]);

    useEffect(() => {
        if (show) {
            obtenerPerfil();
        }
    }, [show, obtenerPerfil]);

    const initialState = {
        usuario: usuarioLogin,
        usuarioActual: usuarioLogin,
        contrasena: '********',
        contrasenaActual: '',
    };

    const [values, setValues] = useState(initialState);

    const evitarEnvioEditarPerfil = async (event) => {
        event.preventDefault();
        if (values.usuario === "") {
            toast.error('USUARIO VACIO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        if (values.contrasena === "") {
            toast.error('CONTRASEÑA VACIA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        let updatedValues = { ...values };

        if (values.contrasena === "********") {
            updatedValues = { ...values, contrasena: values.contrasenaActual };
        }

        //UPDATE
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/ActualizarDatosPerfil.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: updatedValues.usuario, usernameActual: updatedValues.usuarioActual , password: updatedValues.contrasena })
            });

            const data = await response.json();

            if (data.success) {
                setLoading(false);
                toast.success('PERFIL ACTUALIZADO, SE CERRARA SESION', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setValues({ ...values, usuario: '', contrasena: ''})
                setTimeout(function(){
                    localStorage.removeItem("login");
                    document.title = "Login";
                    window.history.replaceState({}, document.title, "/");
                    window.location.reload();
                }, 1000);
                handleClose();
            } else {
                setLoading(false);
                toast.error('NO SE PUDO ACTUALIZAR EL PERFIL', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    }

    return (
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
                <Form onSubmit={evitarEnvioEditarPerfil}>
                    <Modal.Header closeButton>
                        <Modal.Title>EDITAR PERFIL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label htmlFor="nombreEditarPerfil">NOMBRE DE USUARIO</Form.Label>
                                    <Form.Control type='hidden' id="nombreEditarPerfilActual" name='nombreEditarPerfilActual' value={values.usuarioActual}></Form.Control>
                                    <Form.Control type='text' id="nombreEditarPerfil" name='nombreEditarPerfil' placeholder='NOMBRE DE USUARIO' value={values.usuario} onChange={e => setValues({ ...values, usuario: e.target.value })}></Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="contraseñaEditarPerfil">CONTRASEÑA</Form.Label>
                                    <Form.Control type='password' id="contraseñaEditarPerfil" name='contraseñaEditarPerfil' placeholder='CONTRASEÑA' value={values.contrasena} onChange={e => setValues({ ...values, contrasena: e.target.value })}></Form.Control>
                                    <Form.Control type='hidden' id="contraseñaEditarPerfilActual" name='contraseñaEditarPerfilActual' value={values.contrasenaActual}></Form.Control>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> ACTUALIZANDO PERFIL</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">ACEPTAR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    );
}

export default EditarPerfil;
