import { React, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import toast, { Toaster } from 'react-hot-toast';

function EliminarModal({show, handleClose, handleRefetch, rowData, tipo}){

    const [titulo, setTitulo] = useState('');
    const [contenido, setContenido] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            if (tipo === "tractocaja") {
                setTitulo("ELIMINAR TRACTO/CAJA");
                setContenido(`EL TRACTO/CAJA CON ECONOMICO ${rowData.economico.toUpperCase()} Y PLACAS ${rowData.placa.toUpperCase()}`);
            }else if (tipo === "operador"){
                setTitulo("ELIMINAR OPERADOR");
                setContenido(`EL OPERADOR ${rowData.nombre.toUpperCase()} CON LICENCIA ${rowData.licencia.toUpperCase()}`);
            }else if (tipo === "liquidacion"){
                setTitulo("ELIMINAR LIQUIDACION");
                setContenido(`LA LIQUIDACION NO.${rowData.nLiquidacion}`);
            }else if (tipo === "liquidaciongeneral") {
                setTitulo("ELIMINAR LIQUIDACION GENERAL");
                setContenido(`LA LIQUIDACION GENERAL NO.${rowData.nLiquidacion}`);
            }
        }
    }, [show, tipo, rowData]);

    const evitarEnvioEliminarTracto = async (event) => {
        event.preventDefault();
        switch (tipo) {
            case "tractocaja":
                try {
                    setLoading(true);
                    const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_delete/EliminarTractoCaja.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ placa: rowData.placa})
                    });
        
                    const data = await response.json();
        
                    if (data.success) {
                        toast.success('TRACTO/CAJA '+rowData.economico.toUpperCase()+' CON PLACAS '+rowData.placa.toUpperCase()+' ELIMINADO CORRECTAMENTE.', {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                        setLoading(false);
                        handleRefetch();
                        handleClose();
                    } else {
                        setLoading(false);
                        toast.error(data.error, {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    console.error('Error al conectar con el servidor:', error);
                }
                break;

            case "operador":
                try {
                    setLoading(true);
                    const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_delete/EliminarOperador.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ licencia: rowData.licencia})
                    });
        
                    const data = await response.json();
        
                    if (data.success) {
                        toast.success('OPERADOR '+rowData.nombre.toUpperCase()+' CON LICENCIA '+rowData.licencia.toUpperCase()+' ELIMINADO CORRECTAMENTE.', {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                        setLoading(false);
                        handleRefetch();
                        handleClose();
                    } else {
                        setLoading(false);
                        toast.error(data.error, {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    console.error('Error al conectar con el servidor:', error);
                }
                break;
            case "liquidacion":
                try {
                    setLoading(true);
                    const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_delete/EliminarLiquidacion.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ nLiquidacion: rowData.nLiquidacion})
                    });
        
                    const data = await response.json();
        
                    if (data.success) {
                        toast.success('LIQUIDACION NO.'+rowData.nLiquidacion+' ELIMINADA CORRECTAMENTE.', {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                        setLoading(false);
                        handleRefetch();
                        handleClose();
                    } else {
                        setLoading(false);
                        toast.error(data.error, {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    console.error('Error al conectar con el servidor:', error);
                }
                break;
            case "liquidaciongeneral":
                try {
                    setLoading(true);
                    const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_delete/EliminarLiquidacionGeneral.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ nLiquidacion: rowData.nLiquidacion})
                    });
        
                    const data = await response.json();
        
                    if (data.success) {
                        toast.success('LIQUIDACION GENERAL NO.'+rowData.nLiquidacion+' ELIMINADA CORRECTAMENTE.', {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                        setLoading(false);
                        handleRefetch();
                        handleClose();
                    } else {
                        setLoading(false);
                        toast.error(data.error, {
                            style: {
                                padding: '16px',
                                color: 'var(--text-color)',
                                backgroundColor: 'var(--bg-color)',
                            }
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    console.error('Error al conectar con el servidor:', error);
                }
                break;
            default:
                setLoading(false);
                break;
        }
    };

    return(
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static"keyboard={false}>
                <Form onSubmit={evitarEnvioEliminarTracto}>
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <span>SEGURO QUE QUIERES ELIMINAR {contenido}?</span>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormulariosDanger' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> ELIMINANDO</Button>
                            :
                            <Button className='btnFormulariosDanger' variant="primary" type="submit">ELIMINAR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default EliminarModal;