import React, { useEffect, useState } from 'react';
import userLogo from './../user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faPen, faFile, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom'; // Importa useLocation
import Dropdown from 'react-bootstrap/Dropdown';
import Colaboradores from './Colaboradores';
import EditarPerfil from './EditarPerfil';

function Sliderbar() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModalEditarPerfil, setShowModalEditarPerfil] = useState(false);
    const [showModalColaboradores, setShowModalColaboradores] = useState(false);
    const [currentPath, setCurrentPath] = useState('');

    const location = useLocation();

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    useEffect(() => {
        let sidebar = document.querySelector(".sidebar");
        let sidebarBtn = document.querySelector(".bx-menu");

        const handleSidebarToggle = () => {
            sidebar.classList.toggle("close");
        };

        if (sidebarBtn) {
            sidebarBtn.addEventListener("click", handleSidebarToggle);
        }

        return () => {
            if (sidebarBtn) {
                sidebarBtn.removeEventListener("click", handleSidebarToggle);
            }
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("login");
        document.title = "LOGIN";
        window.history.replaceState({}, document.title, "/");
        window.location.reload();
    };

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleModalEditarPerfil = () => {
        setShowModalEditarPerfil(!showModalEditarPerfil);
    };

    const handleModalColaboradores = () => {
        setShowModalColaboradores(!showModalColaboradores);
    };

    const userLogeado = localStorage.getItem("login");
    var userLogeadoPermisos = JSON.parse(userLogeado);
    var permisos = userLogeadoPermisos[0].permisos;

    if (permisos !== "admin") {
        permisos = "USUARIO";
    }

    return (
        <div className="sidebar close">
            <div className="logo-details">
                <i><FontAwesomeIcon icon={faGlobe} /></i>
            </div>
            <ul className="nav-links">
                <li className={currentPath === "/liquidacion" || currentPath === "/" ? "active" : ""}>
                    <Link to="/liquidacion">
                        <i><FontAwesomeIcon icon={faPen} /></i>
                        <span className="link_name">LIQUIDACION OPERADOR</span>
                    </Link>
                    <span className="tooltip">LIQUIDACION OPERADOR</span>
                </li>
                <li className={currentPath === "/liquidaciongeneral" ? "active" : ""}>
                    <Link to="/liquidaciongeneral">
                        <i><FontAwesomeIcon icon={faFile} /></i>
                        <span className="link_name">LIQUIDACION GENERAL</span>
                    </Link>
                    <span className="tooltip">LIQUIDACION GENERAL</span>
                </li>
                <li className={currentPath === "/operadores" ? "active" : ""}>
                    <Link to="/operadores">
                        <i className='bx bxs-user'></i>
                        <span className="link_name">OPERADORES</span>
                    </Link>
                    <span className="tooltip">OPERADORES</span>
                </li>
                <li className={currentPath === "/tractos" ? "active" : ""}>
                    <Link to="/tractos">
                        <i><FontAwesomeIcon icon={faTruck} /></i>
                        <span className="link_name">TRACTO / CAJA</span>
                    </Link>
                    <span className="tooltip">TRACTO / CAJA</span>
                </li>
                <li>
                    <div className="profile-details">
                        <div className="profile-content">
                            <Dropdown show={showDropdown} onToggle={setShowDropdown}>
                                <Dropdown.Toggle as="div" style={{ display: 'inline-block' }}>
                                    <img src={userLogo} alt="profileImg" onClick={handleDropdownToggle} style={{ cursor: 'pointer' }} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu">
                                    <Dropdown.Item onClick={handleModalEditarPerfil}>EDITAR PERFIL</Dropdown.Item>
                                    {permisos === "admin" && (
                                        <Dropdown.Item onClick={handleModalColaboradores}>COLABORADORES</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="name-job">
                            <div className="profile_name">{userLogeadoPermisos[0].usuario.toUpperCase()}</div>
                            <div className="job">{permisos.toUpperCase()}</div>
                        </div>
                        <button onClick={handleLogout} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                            <i className='bx bx-log-out'></i>
                        </button>
                    </div>
                </li>
            </ul>
            <EditarPerfil show={showModalEditarPerfil} handleClose={handleModalEditarPerfil} />
            <Colaboradores show={showModalColaboradores} handleClose={handleModalColaboradores} handleOpen={handleModalColaboradores} />

        </div>
    );
}

export default Sliderbar;
