//FLETE GENERAL

import { updateDineroRecibidoFletes } from "./cuentasGenerales";
import { updateSubTotalSueldoComision } from "./sueldoComision";

export function updateTotalFleteGeneral() {
    var flete = +document.getElementById("amountFleteGeneralEditar").value;
    var iva = document.getElementById("ivaFleteGeneralEditar").value;
    var ret = document.getElementById("retencionFleteGeneralEditar").value;
    var barco = document.getElementById("barcoFleteGeneralEditar").value;
    var transfer = document.getElementById("transferFleteGeneralEditar").value;


    if (iva === 0){
        document.getElementById("totalFleteGeneralEditar").value = flete - ret;
    }else{
        document.getElementById("totalFleteGeneralEditar").value = ((flete * (iva/100)) + flete) - ret;
    }

    // //SUELDO-COMISION
    var comision = document.getElementById("comisionFleteGeneralEditar").value;
    document.getElementById("amountSueldoComisionLiquidacionGeneral").value = flete - comision - barco - transfer;
    ///
    var fechaFleteGeneralExtras = document.querySelectorAll("#extraFieldsFleteGeneral input[type='date']");
    var amountsFleteGeneralExtras = document.querySelectorAll("#extraFieldsFleteGeneral input[type='number']");
    var campoFleteGeneralExtras = document.querySelectorAll("#extraFieldsSueldoComisionLiquidacionGeneral input[type='number']");

    for (let i = 0; i < fechaFleteGeneralExtras.length; i++) {
        let indexFlete = i * 7;
        let indexIva = i * 7 + 1;
        let indexRetencion = i * 7 + 2;
        let indexTotal = i * 7 + 3;
        let indexComision = i * 7 + 4;
        let indexBarco = i * 7 + 5;
        let indexTransfer = i * 7 + 6;

        var fleteFleteGeneralExtra = "";
        var ivaFleteGeneralExtra = amountsFleteGeneralExtras[indexIva].value;
        var retencionFleteGeneralExtra = "";
        var totalFleteFleteGeneralExtra = amountsFleteGeneralExtras[indexTotal].value;

        var comisionSueldoComisionExtra = amountsFleteGeneralExtras[indexComision].value;
        var barcoFleteGeneralExtra = amountsFleteGeneralExtras[indexBarco].value;
        var transferFleteGeneralExtra = amountsFleteGeneralExtras[indexTransfer].value;

        if (ivaFleteGeneralExtra === 0){
            fleteFleteGeneralExtra =+ amountsFleteGeneralExtras[indexFlete].value;
            retencionFleteGeneralExtra = amountsFleteGeneralExtras[indexRetencion].value;
            totalFleteFleteGeneralExtra = fleteFleteGeneralExtra - retencionFleteGeneralExtra;
            amountsFleteGeneralExtras[indexTotal].value = totalFleteFleteGeneralExtra;
        }else{
            fleteFleteGeneralExtra =+ amountsFleteGeneralExtras[indexFlete].value;
            retencionFleteGeneralExtra = amountsFleteGeneralExtras[indexRetencion].value;
            totalFleteFleteGeneralExtra = ((fleteFleteGeneralExtra * (ivaFleteGeneralExtra/100)) + fleteFleteGeneralExtra) - retencionFleteGeneralExtra;
            amountsFleteGeneralExtras[indexTotal].value = totalFleteFleteGeneralExtra;
        }
        campoFleteGeneralExtras[i].value = fleteFleteGeneralExtra - comisionSueldoComisionExtra - barcoFleteGeneralExtra - transferFleteGeneralExtra;
    }

    updateDineroRecibidoFletes();
    updateSubTotalSueldoComision();
}

var camposAdicionalesFleteGeneral = []
export function getInformacionFleteGeneral(){
    var fechaFleteGeneral = document.getElementById("fechaFleteGeneralEditar").value;
    var inicio = document.getElementById("inicioFleteGeneralEditar").value;
    var destino = document.getElementById("destinoFleteGeneralEditar").value;
    var carga = document.getElementById("cargaFleteGeneralEditar").value;
    var fleteFleteGeneral = document.getElementById("amountFleteGeneralEditar").value;
    var ivaFleteGeneral = document.getElementById("ivaFleteGeneralEditar").value;
    var retencionFleteGeneral = document.getElementById("retencionFleteGeneralEditar").value;
    var totalFleteGeneral = document.getElementById("totalFleteGeneralEditar").value;
    var comisionFleteGeneral = document.getElementById("comisionFleteGeneralEditar").value;
    var barcoFleteGeneral = document.getElementById("barcoFleteGeneralEditar").value;
    var transferFleteGeneral = document.getElementById("transferFleteGeneralEditar").value;

    var fechaFleteGeneralExtras = document.querySelectorAll("#extraFieldsFleteGeneral input[type='date']");
    var campoFleteGeneralExtras = document.querySelectorAll("#extraFieldsFleteGeneral input[type='text']");
    var amountsFleteGeneralExtras = document.querySelectorAll("#extraFieldsFleteGeneral input[type='number']");

    camposAdicionalesFleteGeneral.length = 0;
    for (let i = 0; i < fechaFleteGeneralExtras.length; i++) {
        var indexInicio = i * 3;
        var indexDestino = i * 3 + 1;
        var indexCarga = i * 3 + 2;

        var fechaFleteGeneralExtra = fechaFleteGeneralExtras[i].value;
        var inicioFleteGeneralExtra = campoFleteGeneralExtras[indexInicio].value;
        var destinoFleteGeneralExtra = campoFleteGeneralExtras[indexDestino].value;
        var cargaFleteGeneralExtra = campoFleteGeneralExtras[indexCarga].value;

        let indexFlete = i * 7;
        let indexIva = i * 7 + 1;
        let indexRetencion = i * 7 + 2;
        let indexTotal = i * 7 + 3;
        let indexComision = i * 7 + 4;
        let indexBarco = i * 7 + 5;
        let indexTransfer = i * 7 + 6;


        var fleteFleteGeneralExtra = "";
        var ivaFleteGeneralExtra = amountsFleteGeneralExtras[indexIva].value;
        var retencionFleteGeneralExtra = "";
        var totalFleteFleteGeneralExtra = amountsFleteGeneralExtras[indexTotal].value;
        var comisionFleteGeneralExtra = amountsFleteGeneralExtras[indexComision].value;
        var barcoFleteGeneralExtra = amountsFleteGeneralExtras[indexBarco].value;
        var transferFleteGeneralExtra = amountsFleteGeneralExtras[indexTransfer].value;

        if (ivaFleteGeneralExtra === 0){
            fleteFleteGeneralExtra =+ amountsFleteGeneralExtras[indexFlete].value;
            retencionFleteGeneralExtra = amountsFleteGeneralExtras[indexRetencion].value;
            totalFleteFleteGeneralExtra = fleteFleteGeneralExtra - retencionFleteGeneralExtra;
            amountsFleteGeneralExtras[indexTotal].value = totalFleteFleteGeneralExtra;
        }else{
            fleteFleteGeneralExtra =+ amountsFleteGeneralExtras[indexFlete].value;
            retencionFleteGeneralExtra = amountsFleteGeneralExtras[indexRetencion].value;
            totalFleteFleteGeneralExtra = ((fleteFleteGeneralExtra * (ivaFleteGeneralExtra/100)) + fleteFleteGeneralExtra) - retencionFleteGeneralExtra;
            amountsFleteGeneralExtras[indexTotal].value = totalFleteFleteGeneralExtra;
        }

        camposAdicionalesFleteGeneral.push({
            Fecha: fechaFleteGeneralExtra,
            Inicio: inicioFleteGeneralExtra,
            Destino: destinoFleteGeneralExtra,
            Carga: cargaFleteGeneralExtra,
            Cantidad: fleteFleteGeneralExtra,
            ivaFleteGeneralExtra: ivaFleteGeneralExtra,
            retencionFleteGeneralExtra: retencionFleteGeneralExtra,
            totalFleteFleteGeneralExtra: totalFleteFleteGeneralExtra,
            Comision: comisionFleteGeneralExtra,
            Barco: barcoFleteGeneralExtra,
            Transfer: transferFleteGeneralExtra
        });
    }

    if (fechaFleteGeneral === "") {
        return {
            success: false,
            error: "INGRESE LA FECHA N.1 DE FLETE GENERAL"
        };
    }

    if (inicio === "") {
        return {
            success: false,
            error: "INGRESE EL INICIO N.1 DE FLETE GENERAL",
        };
    }

    if (destino === "") {
        return {
            success: false,
            error: "INGRESE EL DESTINO N.1 DE FLETE GENERAL",
        };
    }

    if (carga === "") {
        return {
            success: false,
            error: "INGRESE LA CARGA N.1 DE FLETE GENERAL",
        };
    }

    if (fleteFleteGeneral === "") {
        return {
            success: false,
            error: "INGRESE LA CANTIDAD DE FLETE N.1 DE FLETE GENERAL",
        };
    }

    for (let i = 0; i < fechaFleteGeneralExtras.length; i++) {    
        let indexInicio = i * 3;
        let indexDestino = i * 3 + 1;
        let indexCarga = i * 3 + 2;
        let indexFlete = i * 7;
        
        if (fechaFleteGeneralExtras[i].value === ""){
            return {
                success: false,
                error: "INGRESE LA FECHA N."+(i+2)+" DE FLETE GENERAL"
            };
        }

        if (campoFleteGeneralExtras[indexInicio].value === ""){
            return {
                success: false,
                error: "INGRESE EL INICIO N."+(indexInicio+2)+" DE FLETE GENERAL"
            };
        }

        if (campoFleteGeneralExtras[indexDestino].value === ""){
            return {
                success: false,
                error: "INGRESE EL DESTINO N."+(indexDestino+1)+" DE FLETE GENERAL"
            };
        }

        if (campoFleteGeneralExtras[indexCarga].value === ""){
            return {
                success: false,
                error: "INGRESE LA CARGA N."+(indexCarga)+" DE FLETE GENERAL"
            };
        }

        if (amountsFleteGeneralExtras[indexFlete].value === ""){
            return {
                success: false,
                error: "INGRESE LA CANTIDAD N."+(i+2)+" DE FLETE GENERAL"
            };
        }
    
    }

    var informacionFleteGeneral = {
        fechaSueldoComision: fechaFleteGeneral,
        inicioSueldoComision: inicio,
        destinoSueldoComision: destino,
        cargaSueldoComision: carga,
        amountSueldoComision: fleteFleteGeneral,
        ivaFleteGeneral: ivaFleteGeneral,
        retencionFleteGeneral: retencionFleteGeneral,
        totalFleteGeneral: totalFleteGeneral,
        comisionSueldoComision: comisionFleteGeneral,
        barcoSueldoComision: barcoFleteGeneral,
        transferSueldoComision: transferFleteGeneral,
        camposAdicionalesSueldoComision: camposAdicionalesFleteGeneral
    }

    return informacionFleteGeneral;
}