import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGear, faPenToSquare, faTrash, faPrint} from '@fortawesome/free-solid-svg-icons';
import Sliderbar from '../../utils/Sliderbar';
import EliminarModal from '../../utils/EliminarModal';
import EditarLiquidacionGeneral from '../../utils/EditarLiquidacionGeneral';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import toast, { Toaster } from 'react-hot-toast';
import { useQuery } from "react-query";

function LiquidacionGeneral() {

    const { data: liquidacionesGeneralesData, isLoading, refetch } = useQuery("liquidacionesGenerales", mostrarLiquidacionesGenerales);
    const [rowData, setRowDataLiquidacionGeneral] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [showModalEditarLiquidacionGeneral, setShowModalEditarLiquidacionGeneral] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);

    useEffect(() => {
        document.title = "LIQUIDACION GENERAL";
        refetch();
    }, [refetch]);

    async function mostrarLiquidacionesGenerales(){
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/MostrarLiquidacionesGenerales.php');
            const data = await response.json();

            if (data.success) {
                return data.liquidaciongeneral.map(item => {

                    let conceptosFlete = JSON.parse(item.conceptosFlete);

                    let casetas = "";
                    if (item.casetas !== ""){
                        casetas = JSON.parse(item.casetas);
                    }

                    let gastosRegulares = "";
                    if (item.gastosRegulares !== ""){
                        gastosRegulares = JSON.parse(item.gastosRegulares);
                    }

                    let otrosGastos = "";
                    if (item.otrosGastos){
                        otrosGastos = JSON.parse(item.otrosGastos);
                    }

                    let diesel = "";
                    if (item.diesel !== ""){
                        diesel = JSON.parse(item.diesel);
                    }

                    let conceptosSueldoComision = "";
                    if (item.conceptosSueldoComision !== ""){
                        conceptosSueldoComision = JSON.parse(item.conceptosSueldoComision);
                    }

                    let cuentasGenerales = "";
                    if (item.cuentasGenerales !== ""){
                        cuentasGenerales = JSON.parse(item.cuentasGenerales);
                    }

                    let extra = conceptosFlete.camposAdicionalesSueldoComision;
                    let cargasCombinadas = conceptosFlete.cargaSueldoComision;
                    extra.forEach((adicional) => {
                        cargasCombinadas += ` ${adicional.Carga}`;
                    });

                    return {
                        ...item,
                        fecha_liqGen: item.fecha_liqGen.split('T')[0],
                        conceptosFlete: conceptosFlete,
                        casetas: casetas,
                        gastosRegulares: gastosRegulares,
                        otrosGastos: otrosGastos,
                        diesel: diesel,
                        conceptoSueldoComision: conceptosSueldoComision,
                        cuentasGenerales: cuentasGenerales,
                        cargasCombinadas: cargasCombinadas,
                        conceptosSueldoComision: item.conceptosFlete
                    };
                });
            }else{
                return [];
                // console.log("ERROR AL SELECCIONAR LIQUIDACIONES GENERALES");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            return [];
        }
    }

    const handleShowModalEditarLiquidacionGeneral = (rowData) => {
        setRowDataLiquidacionGeneral(rowData);
        setShowModalEditarLiquidacionGeneral(true);
    };

    const handleCloseModalEditarLiquidacionGeneral = () => {
        setShowModalEditarLiquidacionGeneral(false);
    }

    const handleShowModalEliminar = (rowData) => {
        setRowDataLiquidacionGeneral(rowData)
        setShowModalEliminar(true);
    }

    const handleCloseModalEliminar = () => {
        setShowModalEliminar(false);
    }

    const handleRefetch = () =>{
        refetch();
    }

    const imprimirLiquidacion = async (rowData) => {
        <Toaster position="top-right" reverseOrder={false} />
        try {
            const url = 'https://saulsanchezcelis.com/backendphp-saulsanchez/api/imprimirLiquidacion.php?liquidacionOperadorGeneral='+rowData.nLiquidacion;
            window.location.href = url;

            toast.success("LIQUIDACION GENERAL #"+rowData.nLiquidacion+ " DESCARGADA CORRECTAMENTE", {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });

        } catch(error) {
            console.error('Error al conectar con el servidor:', error);
        }
    }

    const accionBoton = (rowData) => {
        return( 
            <Dropdown drop="left">
                <Dropdown.Toggle className = "btnAccion" variant="success" id="dropdown-basic">
                    <i><FontAwesomeIcon icon={faGear}/></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item key = "editar" onClick={() => handleShowModalEditarLiquidacionGeneral(rowData)}><i><FontAwesomeIcon icon={faPenToSquare} /></i> EDITAR</Dropdown.Item>
                    <Dropdown.Item key = "imprimir" onClick={() => imprimirLiquidacion(rowData)}><i><FontAwesomeIcon icon={faPrint} /></i> IMPRIMIR</Dropdown.Item>
                    <Dropdown.Item key = "borrar" onClick={() => handleShowModalEliminar(rowData)}><i><FontAwesomeIcon icon={faTrash} /></i> BORRAR</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const header = (
        <Col md={3}>
            <Form.Control type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="BUSCAR..."></Form.Control>
        </Col>
    );

    const inicioDestino = (rowData) => {
        const conceptos = rowData.conceptosFlete;
        const camposExtra = conceptos.camposAdicionalesSueldoComision;
        return (
            <div>
                <span>{conceptos.inicioSueldoComision.toUpperCase()} - {conceptos.destinoSueldoComision.toUpperCase()}</span>
                {camposExtra.map((campo, index) => (
                    <div key={index}>
                        <span>{campo.Inicio.toUpperCase()} - {campo.Destino.toUpperCase()}</span>
                    </div>
                ))}
            </div>
        );
    };

    const cargas = (rowData) => {
        const carga = rowData.conceptosFlete;
        const cargaExtra = carga.camposAdicionalesSueldoComision;
        return(
            <div>
                <span>{carga.cargaSueldoComision.toUpperCase()}</span>
                {cargaExtra.map((cargaExtra, index) => (
                    <div key={index}>
                        <span>{cargaExtra.Carga.toUpperCase()}</span>
                    </div>
                ))}
            </div>
        );

    };

    return (
        <div>
            <Sliderbar/>
            <section className="home-section">
                <div className="home-content">
                    <i className='bx bx-menu'></i>
                </div>
                <h4 className='centered'>LIQUIDACION GENERAL</h4>
                <button className = "botton-hidden"></button>
                {
                    isLoading ?
                    <div className='centered'>
                        <div className="spinner"></div>
                        <span><br />CARGANDO LIQUIDACIONES...</span>
                    </div>
                    :
                    <DataTable value={liquidacionesGeneralesData} dataKey="nLiquidacion" stripedRows showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" globalFilter={globalFilter} header={header} emptyMessage="SIN LIQUIDACIONES REGISTRADOS.">
                        <Column field = "nLiquidacion" header="#LIQ" style={{ minWidth: '1px' }} sortable></Column>
                        <Column field = "fecha_liqGen" header="FECHA" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field = "nombreOp" header="OPERADOR" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field = "tracto" header="TRACTO" style={{ minWidth: '10px' }} sortable></Column>
                        <Column field = "caja" header="CAJA" style={{ minWidth: '10px' }} sortable></Column>
                        <Column field = "conceptosSueldoComision" header="INICIO - DESTINO" body = {inicioDestino} style={{ minWidth: '15px' }} sortable></Column>
                        <Column field = "cargasCombinadas" header="CARGAS" body = {cargas} style={{ minWidth: '10px' }} sortable></Column>
                        <Column header = "ACCION" body={accionBoton} style={{ minWidth: '15px' }}/>
                    </DataTable>
                }
            </section>
            <EditarLiquidacionGeneral show={showModalEditarLiquidacionGeneral} handleClose={handleCloseModalEditarLiquidacionGeneral} handleRefetch={handleRefetch} rowData={rowData}/>
            <EliminarModal show={showModalEliminar} handleClose={handleCloseModalEliminar} handleRefetch={handleRefetch} rowData={rowData} tipo={"liquidaciongeneral"}/>
        </div>
    );
}

export default LiquidacionGeneral;
