import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';

function AnadirColaboradores({ show, handleClose}) {
    const colaboradoresInitial = {
        nombreColaborador: '',
        contrasenaColaborador: '',
        crearPermisoColaborador: false,
        editarPermisoColaborador: false,
        borrarPermisoColaborador: false
    };

    const [colaboradores, setColaboradores] = useState(colaboradoresInitial);
    const [loading, setLoading] = useState(false);
    
    const evitarEnvioEditarPerfil = async (event) => {
        event.preventDefault();

        if (colaboradores.nombreColaborador === "") {
            toast.error('USUARIO VACIO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        if (colaboradores.contrasenaColaborador === "") {
            toast.error('CONTRASEÑA VACIA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        var añadirColaboradorPermisos = {
            crear: colaboradores.crearPermisoColaborador,
            editar: colaboradores.editarPermisoColaborador,
            borrar: colaboradores.borrarPermisoColaborador
        };

        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_insert/InsertarColaboradores.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nombreColaborador: colaboradores.nombreColaborador, contrasenaColaborador: colaboradores.contrasenaColaborador, permisos: JSON.stringify(añadirColaboradorPermisos) })
            });

            const data = await response.json();

            if (data.success) {
                setLoading(false);
                toast.success('COLABORADOR ' + colaboradores.nombreColaborador.toUpperCase() + ' AÑADIDO CORRECTAMENTE.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setColaboradores(colaboradoresInitial);
                handleClose();
            } else {
                setLoading(false);
                toast.error('EL COLABORADOR ' + colaboradores.nombreColaborador.toUpperCase() + ' YA SE ENCUENTRA REGISTRADO.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }
    };

    return (
        <div className="modal-content">
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
                <Form onSubmit={evitarEnvioEditarPerfil}>
                    <Modal.Header closeButton>
                        <Modal.Title>AÑADIR COLABORADORES</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label htmlFor="nombreAñadirColaborador">NOMBRE DE USUARIO</Form.Label>
                                    <Form.Control type='text' id="nombreAñadirColaborador" name='nombreAñadirColaborador' placeholder='NOMBRE DE USUARIO' value={colaboradores.nombreColaborador} onChange={e => setColaboradores({ ...colaboradores, nombreColaborador: e.target.value })}></Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="contraseñaEditarPerfil">CONTRASEÑA</Form.Label>
                                    <Form.Control type='password' id="contraseñaEditarPerfil" name='contraseñaEditarPerfil' placeholder='CONTRASEÑA' value={colaboradores.contrasenaColaborador} onChange={e => setColaboradores({ ...colaboradores, contrasenaColaborador: e.target.value })}></Form.Control>
                                </Col>
                            </Row>
                            <h5 className="centered"><br />PERMISOS</h5>
                            <Row>
                                <Col md={4}>
                                    <Form.Label htmlFor="permisoCrearLiquidacion">CREAR</Form.Label>
                                    <Form.Check type="switch" id="permisoCrearLiquidacion" checked={colaboradores.crearPermisoColaborador} onChange={e => setColaboradores({ ...colaboradores, crearPermisoColaborador: e.target.checked })} />
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="permisoEditarLiquidacion">EDITAR</Form.Label>
                                    <Form.Check type="switch" id="permisoEditarLiquidacion" checked={colaboradores.editarPermisoColaborador} onChange={e => setColaboradores({ ...colaboradores, editarPermisoColaborador: e.target.checked })} />
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="permisoBorrarLiquidacion">BORRAR</Form.Label>
                                    <Form.Check type="switch" id="permisoBorrarLiquidacion" checked={colaboradores.borrarPermisoColaborador} onChange={e => setColaboradores({ ...colaboradores, borrarPermisoColaborador: e.target.checked })} />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> CREANDO</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">CREAR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    );
}

export default AnadirColaboradores;
