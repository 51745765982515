import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faFileInvoice, faFileInvoiceDollar, faMoneyBill, faPercent, faTrailer, faTruck, faTruckRampBox, faUser } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function DetallesModal({ show, handleClose, rowData }) {
    const conceptoSC = rowData?.conceptoSueldoComision || {};
    const conceptoSCExtra = conceptoSC?.camposAdicionalesSueldoComision || [];
    const dineroEntregado = rowData?.conceptosDineroEntregado || {};
    const dineroEntregadoExtra = dineroEntregado?.camposAdicionalesDineroEntregado || [];
    const conceptoGastos = rowData?.conceptoGastos || {};
    const conceptoGastosExtra = conceptoGastos?.camposAdicionales || [];
    const cuentasConOperador = rowData?.conceptosCuentasOperador || {};
    const cuentasConOperadorExtra = cuentasConOperador?.camposAdicionalesCuentasOperador || [];

    return (
        <div className='modal-content'>
            <Modal show={show} size="xl" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>DETALLES LIQUIDACION #{rowData?.nLiquidacion}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 d-flex justify-content-around">
                            {/* OPERADOR */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faUser} /></i> OPERADOR</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item ><i><FontAwesomeIcon icon={faUser} /></i> {rowData?.nombreOp}</ListGroup.Item>
                                        <ListGroup.Item><i><FontAwesomeIcon icon={faTruck} /></i> {rowData?.tracto}</ListGroup.Item>
                                        <ListGroup.Item><i><FontAwesomeIcon icon={faTrailer} /></i> {rowData?.caja}</ListGroup.Item>
                                        <ListGroup.Item><i><FontAwesomeIcon icon={faPercent} /></i> {rowData?.porcentajeSueldoComision}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {/* INICIO - DESTINO */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faCirclePlay} /></i> INICIO - DESTINO</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item> {conceptoSC?.inicioSueldoComision} - {conceptoSC?.destinoSueldoComision}</ListGroup.Item>
                                        {conceptoSCExtra.map((campo, index) => (
                                            <ListGroup.Item key={index}>{campo?.Inicio} - {campo?.Destino}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {/* CARGAS */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faTruckRampBox} /></i> CARGAS</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item> <span style={{ color: "yellow" }}>{conceptoSC?.fechaSueldoComision}</span> - {conceptoSC?.cargaSueldoComision} - <span style={{ color: "green" }}>${conceptoSC?.amountSueldoComision}</span></ListGroup.Item>
                                        {conceptoSCExtra.map((campo, index) => (
                                            <ListGroup.Item key={index}><span style={{ color: "yellow" }}>{campo?.Fecha}</span> - {campo?.Carga} - <span style={{ color: "green" }}>${campo?.Cantidad}</span></ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {/* DINERO ENTREGADO */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faMoneyBill} /></i> DINERO ENTREGADO</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item> <span style={{ color: "yellow" }}>{dineroEntregado?.fechaDineroEntregado}</span> - {dineroEntregado?.descriptionDineroEntregado} - <span style={{ color: "green" }}>${dineroEntregado?.amountDineroEntregado}</span></ListGroup.Item>
                                        {dineroEntregadoExtra.map((campo, index) => (
                                            <ListGroup.Item key={index}><span style={{ color: "yellow" }}>{campo?.Fecha}</span> - {campo?.Descripcion} - <span style={{ color: "green" }}>${campo?.Cantidad}</span></ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {/* GASTOS */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faFileInvoice} /></i> GASTOS</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>{conceptoGastos?.descripcion} - <span style={{ color: "green" }}>${conceptoGastos?.cantidad}</span></ListGroup.Item>
                                        {conceptoGastosExtra.map((campo, index) => (
                                            <ListGroup.Item key={index}>{campo?.descripcion} - <span style={{ color: "green" }}>${campo?.cantidad}</span></ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {/* CUENTAS CON OPERADOR */}
                            <Card className='cardDetalles' style={{ width: '30rem', marginBottom: '40px' }}>
                                <Card.Body>
                                    <Card.Title className='text-center'><i><FontAwesomeIcon icon={faFileInvoiceDollar} /></i> CUENTAS CON OPERADOR</Card.Title>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>{cuentasConOperador?.DineroEntregado?.toUpperCase()} - <span style={{ color: "green" }}>${cuentasConOperador?.DineroEntregadoCantidad}</span></ListGroup.Item>
                                        <ListGroup.Item>{cuentasConOperador?.GastosOperador?.toUpperCase()} - <span style={{ color: "green" }}>${cuentasConOperador?.GastosOperadorCantidad}</span></ListGroup.Item>
                                        <ListGroup.Item>{cuentasConOperador?.SobroAbonoSueldo?.toUpperCase()} - <span style={{ color: "green" }}>${cuentasConOperador?.SobroAbonoSueldoCantidad}</span></ListGroup.Item>
                                        <ListGroup.Item>{cuentasConOperador?.SueldoComision?.toUpperCase()} - <span style={{ color: "green" }}>${cuentasConOperador?.SueldoComisionCantidad}</span></ListGroup.Item>

                                        {cuentasConOperadorExtra.map((campo, index) => (
                                            <ListGroup.Item key={index}>{campo?.DescripcionExtraCuentasOperador?.toUpperCase()} - <span style={{ color: "green" }}>${campo?.CantidadExtraCuentasOperador}</span></ListGroup.Item>
                                        ))}

                                        <ListGroup.Item>{"TOTAL A PAGAR A OPERADOR"} - <span style={{ color: "green" }}>${rowData?.totalAPagarOperador}</span></ListGroup.Item>
                                        <ListGroup.Item>{"OBSERVACIONES"} - {rowData?.observacionesCuentasOperador?.toUpperCase()}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default DetallesModal;
