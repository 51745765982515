import React, {useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';

function RegistrarOperador({show, handleClose, handleRefetch}){
    const initialState = {
        nombre: '',
        licencia: ''
    };

    const [valuesOperadores, setValuesOperadores] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const evitarEnvioRegistrarOperador = async (event) => {
        event.preventDefault();
        if (valuesOperadores.nombre === ""){
            toast.error('FALTA NOMBRE', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (valuesOperadores.licencia === ""){
            toast.error('FALTA LA LICENCIA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_insert/InsertarOperador.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nombre: valuesOperadores.nombre, licencia: valuesOperadores.licencia})
            });

            const data = await response.json();

            if (data.success) {
                toast.success('OPERADOR '+valuesOperadores.nombre.toUpperCase()+' CON LICENCIA '+valuesOperadores.licencia.toUpperCase()+' AGREGADO CORRECTAMENTE.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                setValuesOperadores(initialState);
                handleClose();
            } else {
                setLoading(false);
                toast.error('LA LICENCIA '+valuesOperadores.licencia.toUpperCase()+' YA SE ENCUENTRA REGISTRADA.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    return(
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static"keyboard={false}>
                <Form onSubmit={evitarEnvioRegistrarOperador}>
                    <Modal.Header closeButton>
                        <Modal.Title>REGISTRAR OPERADOR</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label htmlFor="nombre">NOMBRE</Form.Label>
                                    <Form.Control type='text' id = "nombre" name='nombre' placeholder='NOMBRE' value={valuesOperadores.nombre} onChange={e => setValuesOperadores({...valuesOperadores, nombre: e.target.value})}></Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="licencia">N. LICENCIA</Form.Label>
                                    <Form.Control type='text' id = "licencia" name='licencia' placeholder='N. LICENCIA' value={valuesOperadores.licencia} onChange={e => setValuesOperadores({...valuesOperadores, licencia: e.target.value})}></Form.Control>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> REGISTRANDO OPERADOR</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">REGISTRAR OPERADOR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default RegistrarOperador