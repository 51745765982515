export function updateTotalCuentasOperadorEditar() {
    var dineroEntregadoOperadorEditar = document.getElementById("totalAmountDineroEntregadoEditar").value;
    var gastosQueHizoOperadorEditar = document.getElementById("totalAmountGastosEditar").value;
    document.getElementById("amountCuentasOperador3Editar").value = dineroEntregadoOperadorEditar - gastosQueHizoOperadorEditar;

    var sobroAbonoASuSueldoEditar = document.getElementById("amountCuentasOperador3Editar").value;
    var sueldoComisionTotalEditar = document.getElementById("amountCuentasOperador4Editar").value;
    var totalExtrasCuentasOperadorEditar = 0;
    var cantidadesExtrasCuentasOperadorEditar = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='number']");
    cantidadesExtrasCuentasOperadorEditar.forEach(function(amountInputCuentasOperadorEditar) {
        totalExtrasCuentasOperadorEditar += parseFloat(amountInputCuentasOperadorEditar.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperadorEditar").value = (sueldoComisionTotalEditar - sobroAbonoASuSueldoEditar - totalExtrasCuentasOperadorEditar).toFixed(3);
}

var camposAdicionalesCuentasOperador = [];
export function getInformacionCuentasOperadorEditar(){
    var descriptionCuentasOperador1 = document.getElementById("descriptionCuentasOperador1Editar").value;
    var amountCuentasOperador1 = document.getElementById("amountCuentasOperador1Editar").value;

    var descriptionCuentasOperador2 = document.getElementById("descriptionCuentasOperador2Editar").value;
    var amountCuentasOperador2 = document.getElementById("amountCuentasOperador2Editar").value;

    var descriptionCuentasOperador3 = document.getElementById("descriptionCuentasOperador3Editar").value;
    var amountCuentasOperador3 = document.getElementById("amountCuentasOperador3Editar").value;

    var descriptionCuentasOperador4 = document.getElementById("descriptionCuentasOperador4Editar").value;
    var amountCuentasOperador4 = document.getElementById("amountCuentasOperador4Editar").value;

    var observacionesCuentasOperador = document.getElementById("observacionesCuentasOperadorEditar").value;
    var totalAmountCuentasOperador = document.getElementById("totalAmountCuentasOperadorEditar").value;
    
    var descripcionCuentasOperadorExtras = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='text']");
    var cantidadCuentasOperadorExtras = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='number']");

    camposAdicionalesCuentasOperador.length = 0;

    for (var i = 0; i < descripcionCuentasOperadorExtras.length; i++) {
        var descripcionDineroCuentasOperador = descripcionCuentasOperadorExtras[i].value;
        var cantidadDineroCuentasOperador = cantidadCuentasOperadorExtras[i].value;

        camposAdicionalesCuentasOperador.push({DescripcionExtraCuentasOperador: descripcionDineroCuentasOperador, CantidadExtraCuentasOperador: cantidadDineroCuentasOperador });
    }

    for (let i = 0; i < descripcionCuentasOperadorExtras.length; i++) {
        if (descripcionCuentasOperadorExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA DESCRIPCION N."+(i+1)+" DE CUENTAS CON OPERADOR"
            };
        }

        if (cantidadCuentasOperadorExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA CANTIDAD N."+(i+1)+" DE CUENTAS CON OPERADOR"
            };
        }
        
    }

    var informacionCuentasOperador = {
        DineroEntregado: descriptionCuentasOperador1,
        DineroEntregadoCantidad: amountCuentasOperador1,
        GastosOperador: descriptionCuentasOperador2,
        GastosOperadorCantidad: amountCuentasOperador2,
        SobroAbonoSueldo: descriptionCuentasOperador3,
        SobroAbonoSueldoCantidad: amountCuentasOperador3,
        SueldoComision: descriptionCuentasOperador4,
        SueldoComisionCantidad: amountCuentasOperador4,
        camposAdicionalesCuentasOperador: camposAdicionalesCuentasOperador,

        observacionesCuentasOperador: observacionesCuentasOperador,
        TotalAPagarCuentasOperador: totalAmountCuentasOperador
    };

    return informacionCuentasOperador;
}