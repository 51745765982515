//SUELDO-COMISION
import { updateGastosGeneralesEnLaVuelta } from "./cuentasGenerales";

export function updateSubTotalSueldoComision(){
    var subTotalSueldoComision = 0;
    var defaultAmount = document.getElementById("amountSueldoComisionLiquidacionGeneral").value;
    subTotalSueldoComision += parseFloat(defaultAmount) || 0;
    var amountsSueldoComision = document.querySelectorAll("#extraFieldsSueldoComisionLiquidacionGeneral input[type='number']");
    amountsSueldoComision.forEach(function(amountInputSueldoComision) {
        subTotalSueldoComision += parseFloat(amountInputSueldoComision.value) || 0;
    });
    document.getElementById("subTotalAmountSueldoComisionLiquidacionGeneral").value = subTotalSueldoComision;
    porcentajeSueldoComision();
}

function porcentajeSueldoComision(){
    var subTotal = document.getElementById("subTotalAmountSueldoComisionLiquidacionGeneral").value;
    var porcentaje = document.getElementById("porcentajeAmountSueldoComision").value;

    var resultado = (subTotal * (porcentaje / 100)).toFixed(3);
    document.getElementById("totalAmountSueldoComision").value = resultado;
    updateGastosGeneralesEnLaVuelta();
}

var camposAdicionalesSueldoComision = [];
export function getInformacionSueldoComisionOperador(){
    var inicioSueldoComision = document.getElementById("inicioSueldoComision").value;
    var destinoSueldoComision = document.getElementById("destinoSueldoComision").value;
    var amountSueldoComision = document.getElementById("amountSueldoComisionLiquidacionGeneral").value;

    var fechas = document.querySelectorAll("#extraFieldsFleteGeneral input[type='date']");
    var camposExtrasSueldoComision = document.querySelectorAll("#extraFieldsSueldoComisionLiquidacionGeneral input[type='text']");
    var camposExtrasCantidadComision = document.querySelectorAll("#extraFieldsSueldoComisionLiquidacionGeneral input[type='number']");
    camposAdicionalesSueldoComision.length = 0;
    
    for (var i = 0; i < fechas.length; i++) {
        var indexInicio = i * 2;
        var indexDestino = i * 2 + 1;

        var campoExtraInicioSueldoComision = camposExtrasSueldoComision[indexInicio].value;
        var campoExtraDestinoSueldoComision = camposExtrasSueldoComision[indexDestino].value;
        var campoExtraCantidadSueldoComision = camposExtrasCantidadComision[i].value
        
        camposAdicionalesSueldoComision.push({ 
            Inicio: campoExtraInicioSueldoComision,
            Destino: campoExtraDestinoSueldoComision,
            Cantidad: campoExtraCantidadSueldoComision
        });
    }
    
    var subTotalAmountSueldoComision = document.getElementById("subTotalAmountSueldoComisionLiquidacionGeneral").value;
    var totalAmountSueldoComision = document.getElementById("totalAmountSueldoComision").value;

    var informacionSueldoComision = {
        inicioSueldoComision: inicioSueldoComision,
        destinoSueldoComision: destinoSueldoComision,
        amountSueldoComision: amountSueldoComision,
        camposAdicionalesSueldoComision: camposAdicionalesSueldoComision,
        subTotalAmountSueldoComision: subTotalAmountSueldoComision,
        totalAmountSueldoComision: totalAmountSueldoComision
    }

    return informacionSueldoComision;
}