import React, { useState, useEffect, useCallback } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';


function EditarColaboradores({ show, handleClose, colaboradorSeleccionado}){

    const initialEditarColaboradores = {
        usuario: '',
        contrasena: '',
        permisos: { crear: false, editar: false, borrar: false }
    }

    const [editarColaboradores, setEditarColaboradores] = useState(initialEditarColaboradores);
    const [loading, setLoading] = useState(false);
    const [loadingColaboradores, setLoadingColaboradores] = useState(false);

    const obtenerDatosColaborador = useCallback(async () =>{
        try {
            setLoadingColaboradores(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/ObtenerDatosColaborador.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: colaboradorSeleccionado })
            });

            const data = await response.json();

            if (data.success) {
                setLoadingColaboradores(false);
                setEditarColaboradores((prevValues) => ({ ...prevValues, usuario:data.colaborador.usuario ,contrasena: data.colaborador.contrasena, permisos: JSON.parse(data.colaborador.permisos) }));
            } else {
                setLoadingColaboradores(false);
                console.log("ERROR AL OBTENER DATOS DEL COLABORADOR");
            }
        } catch (error) {
            setLoadingColaboradores(false);
            console.error('Error al conectar con el servidor:', error);
        }
    }, [colaboradorSeleccionado]);

    useEffect(() => {
        if (show) {
            obtenerDatosColaborador();
        }
    }, [show, obtenerDatosColaborador]);

    const evitarEnvioEditarColaboradores = async (event) => {
        event.preventDefault();

        if (editarColaboradores.usuario === "") {
            toast.error('USUARIO VACIO', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        if (editarColaboradores.contrasena === "") {
            toast.error('CONTRASEÑA VACIA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return false;
        }

        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/ActualizarDatosColaboradores.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: editarColaboradores.usuario, usernameActual: colaboradorSeleccionado , password: editarColaboradores.contrasena, permisos: JSON.stringify(editarColaboradores.permisos) })
            });

            const data = await response.json();

            if (data.success) {
                setLoading(false);
                toast.success('COLABORADOR '+editarColaboradores.usuario.toUpperCase()+ ' EDITADO CORRECTAMENTE', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setEditarColaboradores({ ...editarColaboradores, usuario: '', contrasena: ''});
                handleClose();
            } else {
                setLoading(false);
                toast.error("COLABORADOR "+editarColaboradores.usuario.toUpperCase()+" YA EXISTE", {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    return(
        <div className="modal-content">
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false}>
                <Form onSubmit={evitarEnvioEditarColaboradores}>
                    <Modal.Header closeButton>
                        <Modal.Title>EDITAR COLABORADORES</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            loadingColaboradores ?
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <span className="placeholder col-6"></span><br />
                                        <span className="placeholder col-12"></span>
                                    </Col>
                                    <Col md={6}>
                                        <span className="placeholder col-6"></span><br />
                                        <span className="placeholder col-12"></span>
                                    </Col>
                                </Row>
                                <span className="placeholder col-12"></span>
                            </Container>
                            :
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control type='hidden' id="nombreEditarColaboradorActual" value={colaboradorSeleccionado}></Form.Control>
                                        <Form.Label htmlFor="nombreEditarColaborador">NOMBRE DE USUARIO</Form.Label>
                                        <Form.Control type='text' id="nombreEditarColaborador" name='nombreEditarColaborador' placeholder='NOMBRE DE USUARIO' value={editarColaboradores.usuario} onChange={e => setEditarColaboradores({ ...editarColaboradores, usuario: e.target.value })}></Form.Control>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="contraseñaEditarColaborador">CONTRASEÑA</Form.Label>
                                        <Form.Control type='text' id="contraseñaEditarColaborador" name='contraseñaEditarColaborador' placeholder='CONTRASEÑA' value ={editarColaboradores.contrasena} onChange={e => setEditarColaboradores({ ...editarColaboradores, contrasena: e.target.value })}></Form.Control>
                                    </Col>
                                </Row>
                                <h5 className="centered"><br />PERMISOS</h5>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label htmlFor="permisoCrearLiquidacionEditar">CREAR</Form.Label>
                                        <Form.Check type="switch" id="permisoCrearLiquidacionEditar" checked = {editarColaboradores.permisos.crear} onChange={(e) => {setEditarColaboradores(prevState => ({...prevState, permisos: {...prevState.permisos, crear: e.target.checked}}))}}/>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="permisoEditarLiquidacionEditar">EDITAR</Form.Label>
                                        <Form.Check type="switch" id="permisoEditarLiquidacionEditar" checked = {editarColaboradores.permisos.editar} onChange={(e) => {setEditarColaboradores(prevState => ({...prevState, permisos: {...prevState.permisos, editar: e.target.checked}}))}}/>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="permisoBorrarLiquidacionEditar">BORRAR</Form.Label>
                                        <Form.Check type="switch" id="permisoBorrarLiquidacionEditar" checked = {editarColaboradores.permisos.borrar} onChange={(e) => {setEditarColaboradores(prevState => ({...prevState, permisos: {...prevState.permisos, borrar: e.target.checked}}))}}/>
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> EDITANDO</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">EDITAR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    );
}

export default EditarColaboradores