import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import './css/index.css';
import './css/style.css';
import "primereact/resources/themes/mdc-dark-indigo/theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query"
// import { ReactQueryDevtools } from "react-query/devtools"

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            enabled: false
        }
    }
});
root.render(
    <BrowserRouter>
        <QueryClientProvider client = {queryClient}>
            <App />
            {/* <ReactQueryDevtools/> */}
        </QueryClientProvider>
    </BrowserRouter>
);