//GASTOS EDITAR
export function updateTotalGastosEditar() {
    var totalGastosEditar = 0;
    var defaultAmountEditar = document.getElementById("amountEditar").value;
    totalGastosEditar += parseFloat(defaultAmountEditar) || 0;
    var amountsEditar = document.querySelectorAll("#extraFieldsGastosEditar input[type='number']");
    amountsEditar.forEach(function(amountInputEditar) {
        totalGastosEditar += parseFloat(amountInputEditar.value) || 0;
    });
    document.getElementById("totalAmountGastosEditar").value = totalGastosEditar;

    //CUENTAS CCON EL OPERADOR EDITAR
    document.getElementById("amountCuentasOperador2Editar").value = totalGastosEditar;

    var dineroEntregadoOperadorEditar = document.getElementById("totalAmountDineroEntregadoEditar").value;
    var gastosQueHizoOperadorEditar = document.getElementById("totalAmountGastosEditar").value;
    document.getElementById("amountCuentasOperador3Editar").value = dineroEntregadoOperadorEditar - gastosQueHizoOperadorEditar;

    var sobroAbonoASuSueldoEditar = document.getElementById("amountCuentasOperador3Editar").value;
    var sueldoComisionTotalEditar = document.getElementById("amountCuentasOperador4Editar").value;
    var totalExtrasCuentasOperadorEditar = 0;
    var cantidadesExtrasCuentasOperadorEditar = document.querySelectorAll("#extraFieldsCuentasOperadorEditar input[type='number']");
    cantidadesExtrasCuentasOperadorEditar.forEach(function(amountInputCuentasOperadorEditar) {
        totalExtrasCuentasOperadorEditar += parseFloat(amountInputCuentasOperadorEditar.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperadorEditar").value = sueldoComisionTotalEditar - sobroAbonoASuSueldoEditar - totalExtrasCuentasOperadorEditar;
}

var camposAdicionales = [];
export function getInformacionGastosOperadorEditar(){
    var descripcion = document.getElementById("descriptionEditar").value;
    var cantidad = document.getElementById("amountEditar").value;
    var totalGastos = document.getElementById("totalAmountGastosEditar").value;
    var camposExtras = document.querySelectorAll("#extraFieldsGastosEditar input[type='text']");
    var cantidadesExtras = document.querySelectorAll("#extraFieldsGastosEditar input[type='number']");
    camposAdicionales.length = 0;
    
    for (let i = 0; i < camposExtras.length; i++) {
        var campoExtra = camposExtras[i].value;
        var cantidadExtra = cantidadesExtras[i].value;
        camposAdicionales.push({ descripcion: campoExtra, cantidad: cantidadExtra });
    }

    if (descripcion === "") {
        return {
            success: false,
            error: "Ingrese la descripción N.1 de GASTOS"
        };
    }

    if (cantidad === "") {
        return {
            success: false,
            error: "Ingrese la cantidad N.1 de GASTOS"
        };
    }
    
    for (let i = 0; i < camposExtras.length; i++) {
        if (camposExtras[i].value === ""){
            return {
                success: false,
                error: "Ingrese la descripcion N."+(i+2)+" de GASTOS"
            };
        }
    
        if (cantidadesExtras[i].value === ""){
            return {
                success: false,
                error: "Ingrese la cantidad N."+(i+2)+" de GASTOS"
            };
        }
    }

    var informacionGastos = {
        descripcion: descripcion,
        cantidad: cantidad,
        camposAdicionales: camposAdicionales,
        totalGastos: totalGastos
    };
    
    return informacionGastos;
}