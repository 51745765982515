export function updateTotalCuentasOperador() {
    var dineroEntregadoOperador = document.getElementById("totalAmountDineroEntregado").value;
    var gastosQueHizoOperador = document.getElementById("totalAmountGastos").value;
    document.getElementById("amountCuentasOperador3").value = dineroEntregadoOperador - gastosQueHizoOperador;

    var sobroAbonoASuSueldo = document.getElementById("amountCuentasOperador3").value;
    var sueldoComisionTotal = document.getElementById("amountCuentasOperador4").value;
    var totalExtrasCuentasOperador = 0;
    var cantidadesExtrasCuentasOperador = document.querySelectorAll("#extraFieldsCuentasOperador input[type='number']");
    cantidadesExtrasCuentasOperador.forEach(function(amountInputCuentasOperador) {
        totalExtrasCuentasOperador += parseFloat(amountInputCuentasOperador.value) || 0;
    });

    document.getElementById("totalAmountCuentasOperador").value = (sueldoComisionTotal - sobroAbonoASuSueldo - totalExtrasCuentasOperador).toFixed(3);
}

var camposAdicionalesCuentasOperador = [];
export function getInformacionCuentasOperador(){
    var descriptionCuentasOperador1 = document.getElementById("descriptionCuentasOperador1").value;
    var amountCuentasOperador1 = document.getElementById("amountCuentasOperador1").value;

    var descriptionCuentasOperador2 = document.getElementById("descriptionCuentasOperador2").value;
    var amountCuentasOperador2 = document.getElementById("amountCuentasOperador2").value;

    var descriptionCuentasOperador3 = document.getElementById("descriptionCuentasOperador3").value;
    var amountCuentasOperador3 = document.getElementById("amountCuentasOperador3").value;

    var descriptionCuentasOperador4 = document.getElementById("descriptionCuentasOperador4").value;
    var amountCuentasOperador4 = document.getElementById("amountCuentasOperador4").value;

    var observacionesCuentasOperador = document.getElementById("observacionesCuentasOperador").value;
    var totalAmountCuentasOperador = document.getElementById("totalAmountCuentasOperador").value;
    
    var descripcionCuentasOperadorExtras = document.querySelectorAll("#extraFieldsCuentasOperador input[type='text']");
    var cantidadCuentasOperadorExtras = document.querySelectorAll("#extraFieldsCuentasOperador input[type='number']");

    camposAdicionalesCuentasOperador.length = 0;

    for (var i = 0; i < descripcionCuentasOperadorExtras.length; i++) {
        var descripcionDineroCuentasOperador = descripcionCuentasOperadorExtras[i].value;
        var cantidadDineroCuentasOperador = cantidadCuentasOperadorExtras[i].value;

        camposAdicionalesCuentasOperador.push({DescripcionExtraCuentasOperador: descripcionDineroCuentasOperador, CantidadExtraCuentasOperador: cantidadDineroCuentasOperador });
    }

    for (let i = 0; i < descripcionCuentasOperadorExtras.length; i++) {
        if (descripcionCuentasOperadorExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA DESCRIPCION N."+(i+1)+" DE CUENTAS CON OPERADOR"
            };
        }

        if (cantidadCuentasOperadorExtras[i].value === "") {
            return {
                success: false,
                error: "INGRESE LA CANTIDAD N."+(i+1)+" DE CUENTAS CON OPERADOR"
            };
        }
        
    }

    var informacionCuentasOperador = {
        DineroEntregado: descriptionCuentasOperador1,
        DineroEntregadoCantidad: amountCuentasOperador1,
        GastosOperador: descriptionCuentasOperador2,
        GastosOperadorCantidad: amountCuentasOperador2,
        SobroAbonoSueldo: descriptionCuentasOperador3,
        SobroAbonoSueldoCantidad: amountCuentasOperador3,
        SueldoComision: descriptionCuentasOperador4,
        SueldoComisionCantidad: amountCuentasOperador4,
        camposAdicionalesCuentasOperador: camposAdicionalesCuentasOperador,

        observacionesCuentasOperador: observacionesCuentasOperador,
        TotalAPagarCuentasOperador: totalAmountCuentasOperador
    };

    return informacionCuentasOperador;
}