import { React, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTruck, faGear, faPenToSquare, faTrash} from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Sliderbar from '../../utils/Sliderbar';
import RegistrarTractoCaja from '../../utils/RegistrarTractoCaja';
import EditarTractoCaja from '../../utils/EditarTractoCaja';
import EliminarModal from '../../utils/EliminarModal';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQuery } from "react-query";

function Tracto() {
    const [show, setShow] = useState(false);
    const [showEliminar, setShowEliminar] = useState(false);
    const [showModalEditarTracto, setShowModalEditarTracto] = useState(false);
    const { data: tractosData, isLoading, refetch } = useQuery("tractos", mostrarTractosCajas);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [rowData, setRowDataTracto] = useState([]);

    useEffect(() => {
        document.title = "TRACTOS - CAJAS";
        refetch();
    }, [refetch]);

    async function mostrarTractosCajas() {
        try {
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_select/MostrarTractoCaja.php');
            const data = await response.json();
    
            if (data.success) {
                return data.tracto;
            } else {
                return [];
                // console.log("ERROR AL SELECCIONAR TRACTOS/CAJAS");
            }
        } catch (error) {
            console.error('Error al conectar con el servidor:', error);
            return [];
        }
        
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleOpenModalEditarTracto = (rowData) => {
        setRowDataTracto(rowData);
        setShowModalEditarTracto(true);
    }

    const handleCloseModalEditarTracto = () => {
        setShowModalEditarTracto(false);
    }

    const handleShowModalEliminarTracto = (rowData) => {
        setRowDataTracto(rowData);
        setShowEliminar(true);
    }

    const handleCloseModalEliminarTracto = () => {
        setShowEliminar(false);
    }

    const handleRefetch = () =>{
        refetch();
    }

    const accionBoton = (rowData) => {
        return( 
            <Dropdown drop="left">
                <Dropdown.Toggle className = "btnAccion" variant="success" id="dropdown-basic">
                    <i><FontAwesomeIcon icon={faGear}/></i>
                </Dropdown.Toggle>
        
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleOpenModalEditarTracto(rowData)}><i><FontAwesomeIcon icon={faPenToSquare} /></i> EDITAR</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowModalEliminarTracto(rowData)}><i><FontAwesomeIcon icon={faTrash} /></i> BORRAR</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const header = (
        <Col md={3}>
            <Form.Control type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="BUSCAR..."></Form.Control>
        </Col>
    );

    return (
        <div>
            <Sliderbar/>
            <section className="home-section">
                <div className="home-content">
                    <i className='bx bx-menu'></i>
                </div>
                <h4 className='centered'>TRACTOS/CAJAS</h4>
                <Button className='buttonNew' variant="primary" onClick={handleShow}><i><FontAwesomeIcon icon={faTruck}/></i> NUEVO</Button>
                <RegistrarTractoCaja show={show} handleClose={handleClose} handleRefetch={handleRefetch} />

                {
                    isLoading ?
                    <div className='centered'>
                        <div className="spinner"></div>
                        <span><br />CARGANDO TRACTOS/CAJAS...</span>
                    </div>
                    :
                    <DataTable value={tractosData} dataKey="placa" stripedRows showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" globalFilter={globalFilter} header={header} emptyMessage="SIN TRACTOS/CAJAS REGISTRADOS.">
                        <Column field="economico" header="N. ECONOMICO" style={{ minWidth: '18px' }} sortable></Column>
                        <Column field="placa" header="PLACAS" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field="modelo" header="MODELO" style={{ minWidth: '25px' }} sortable></Column>
                        <Column field="tipo" header="TIPO" style={{ minWidth: '25px' }} sortable></Column>
                        <Column header="ACCION" body={accionBoton} style={{ minWidth: '15px' }}/>
                    </DataTable>
                }

            </section>
            <EditarTractoCaja show = {showModalEditarTracto} handleClose={handleCloseModalEditarTracto} handleRefetch={handleRefetch} rowData={rowData}/>
            <EliminarModal show = {showEliminar} handleClose={handleCloseModalEliminarTracto} handleRefetch={handleRefetch} rowData={rowData} tipo = {"tractocaja"}/>
        </div>
    );
}

export default Tracto;
