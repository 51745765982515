import React, {useState, useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';

function EditarOperadores({show, handleClose, handleRefetch, rowData}){

    const initialState = {
        nombre: "",
        licencia: "",
    };

    const [valuesOperadoresEditar, setValuesOperadoresEditar] = useState(initialState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setValuesOperadoresEditar((prevValues) => ({ ...prevValues, nombre: rowData.nombre, licencia: rowData.licencia}));
        }
    }, [show, rowData]);

    const evitarEnvioEditarOperador = async (event) => {
        event.preventDefault();
        if (valuesOperadoresEditar.nombre === ""){
            toast.error('FALTA NOMBRE', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }

        if (valuesOperadoresEditar.licencia === ""){
            toast.error('FALTA LA LICENCIA', {
                style: {
                    padding: '16px',
                    color: 'var(--text-color)',
                    backgroundColor: 'var(--bg-color)',
                }
            });
            return;
        }
        
        try {
            setLoading(true);
            const response = await fetch('https://saulsanchezcelis.com/backendphp-saulsanchez/_update/ActualizarOperador.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nombre: valuesOperadoresEditar.nombre, licencia: valuesOperadoresEditar.licencia, licenciaActual: rowData.licencia})
            });

            const data = await response.json();

            if (data.success) {
                toast.success('OPERADOR '+valuesOperadoresEditar.nombre.toUpperCase()+' CON LICENCIA '+valuesOperadoresEditar.licencia.toUpperCase()+' EDITADO CORRECTAMENTE.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
                setLoading(false);
                handleRefetch();
                handleClose();
            } else {
                setLoading(false);
                toast.error('LA LICENCIA '+valuesOperadoresEditar.licencia.toUpperCase()+' YA SE ENCUENTRA REGISTRADA.', {
                    style: {
                        padding: '16px',
                        color: 'var(--text-color)',
                        backgroundColor: 'var(--bg-color)',
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectar con el servidor:', error);
        }

    };

    return(
        <div className='modal-content'>
            <Modal show={show} size="lg" onHide={handleClose} backdrop="static"keyboard={false}>
                <Form onSubmit={evitarEnvioEditarOperador}>
                    <Modal.Header closeButton>
                        <Modal.Title>EDITAR OPERADOR</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label htmlFor="nombreEditar">NOMBRE</Form.Label>
                                    <Form.Control type='text' id = "nombreEditar" name='nombreEditar' placeholder='NOMBRE' value={valuesOperadoresEditar.nombre} onChange={e => setValuesOperadoresEditar({...valuesOperadoresEditar, nombre: e.target.value})}></Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="licenciaEditar">N. LICENCIA</Form.Label>
                                    <Form.Control type='text' id = "licenciaEditar" name='licenciaEditar' placeholder='N. LICENCIA' value={valuesOperadoresEditar.licencia} onChange={e => setValuesOperadoresEditar({...valuesOperadoresEditar, licencia: e.target.value})}></Form.Control>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>CERRAR</Button>
                        {
                            loading ?
                            <Button disabled className='btnFormularios' variant="primary" type="submit"><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> ACTUALIZANDO OPERADOR</Button>
                            :
                            <Button className='btnFormularios' variant="primary" type="submit">EDITAR OPERADOR</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default EditarOperadores;